import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	booleanAttribute,
} from "@angular/core"
import {
	ConfigDefinitions,
	ExtendedInputFormClass,
} from "@app/definitions/types"
import { EventsService } from "@app/services/events.service"
import { InputsService } from "@app/services/inputs.service"
import { StoreService } from "@app/services/store.service"

@Component({
	selector: "app-gen-input",
	templateUrl: "./gen-input.component.html",
})
export class GenInputComponent extends ExtendedInputFormClass {
	@Input() type: string = "text" //any type OR textarea
	@Input() height: string = "auto"
	@Input({ transform: booleanAttribute }) isNumber: boolean = false
	@Input() searchStr: string = ""
	@Input() showPassword: boolean = false
	@Input({ transform: booleanAttribute }) numberKeyboard: boolean = false

	@ViewChild("elementEl") elementEl //the input element. used for focus
	constructor(
		protected eventsService: EventsService,
		private store: StoreService,
		private inputsService: InputsService
	) {
		super()
	}

	ngOnInit() {
		this.inputsService.loadVarsToInput(this)
		if (this.isNumber) {
			this.className += " input-number"
		}
	}

	focus(scrollToTop: boolean = false) {
		//called from parent. focuses the input (can also scroll into view)
		this.elementEl.nativeElement.focus()
		if (scrollToTop) {
			this.elementEl.nativeElement.scrollIntoView(true)
		}
	}

	async ngAfterViewInit() {
		//after view is loaded - deal with focus if needed
		if (this.autofocus && !this.eventsService.isTouch) {
			//if has focus and screen isn't touch - focus on input
			await this.store.timeout(50)
			this.elementEl.nativeElement.focus()
		}
	}

	onkeyup() {
		//emit keyup event to parent (nothing passed)
		this.keyupEvent.emit()
	}
	onchange() {
		//emit change to parent (nothing passed)
		this.changeEvent.emit()
	}
	handleClick() {
		if (this.isNumber) {
			this.elementEl.nativeElement.select()
		}
	}
}
