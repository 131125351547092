<ng-template #header>
	<h3>{{ patient?.name }}</h3>
</ng-template>
@if (isHomeMeetingOnly) {
	<app-home-meeting
		[followup]="followup"
		[patient]="patient"
		isModal
		(moveToOtherFollowup)="moveToOtherFollowup.emit($event)"
		(addDocumentEv)="addMedicalLetter()"
		(addInvoiceEv)="addInvoice()"
		(openUploadDocumentModalEv)="openUploadDocumentModal()"
	/>
} @else {
	@if (!isHome) {
		<app-mobile-tabs
			[activeColumn]="showColumn"
			personType="patient"
			(onClick)="updateShowColumnLocal($event)"
		/>
	}

	<app-banner
		[isLoading]="isLoading"
		[person]="patient"
		(onClick)="changeCurCenterToPatientDetails()"
	></app-banner>

	<div class="row person-content">
		<!-- comments for side scrolling:
    <div class="row person-content"  [style.width]="(sideWidth*2)+'px'" >
      <div class="col-6 col-lg-8 center-area" #center> -->

		<div
			[hidden]="
				eventsService.isMobile &&
				(showColumn | inArray: ['calendar', 'sideItems'])
			"
			class="col-12 col-lg-8 center-area"
			#center
		>
			<!--side-scroll -->
			@if (isLoading || (!followup && isHome && !curCenter)) {
				<div class="center">
					<div>
						<div class="panel panel-default">
							<div class="panel-heading"></div>
							<div class="panel-body no-followup-container">
								@if (isLoading) {
									<div
										class="loading-spinner"
										spin
									></div>
								} @else {
									<p class="no-followup-title">
										@switch (timeStatus) {
											@case ("past") {
												<span
													[innerHTML]="
														'there_are_no_followups_past' | langVal | safeHtml
													"
												></span>
											}
											@case ("present") {
												<span
													[innerHTML]="
														'there_are_no_followups_present'
															| langVal
															| safeHtml
													"
												></span>
											}
											@case ("future") {
												@if (dayEventsLength) {
													<span
														[innerHTML]="
															'there_are_no_followups_but_fus_future'
																| langVal
																| safeHtml
														"
													></span>
												} @else {
													<span
														[innerHTML]="
															'there_are_no_followups_future'
																| langVal
																| safeHtml
														"
													></span>
												}
											}
										}
									</p>
									@if (timeStatus != "past") {
										<p
											class="no-followup-title"
											style="margin-top: 20px"
										>
											<span
												[innerHTML]="
													'there_are_no_followups_click_to_set'
														| langVal
														| safeHtml
												"
											></span>
										</p>
									}
								}
								<!-- {{(isLoading ? "loading" : "there_are_no_followups") | langVal}} -->
							</div>
						</div>
					</div>
				</div>
			}
			@if (!isLoading && patient) {
				@switch (curCenter) {
					@case ("patient_details") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
									<hr />
									<h3
										class="green"
										data-toggle="tooltip"
										[titleLang]="patient.is_group == 'no' ? 'patient' : 'group'"
									>
										{{
											(patient.is_group == "no"
												? "patient_details"
												: "group_details"
											) | langVal
										}}
									</h3>
								</div>
							</div>
							<div class="panel-body">
								@if (patient.is_group == "no") {
									<app-update-patient
										[patient]="patient"
										(addDocumentEv)="addDocument()"
										(addInvoiceEv)="addInvoice()"
										(openUploadDocumentModalEv)="openUploadDocumentModal()"
									/>
								} @else {
									<app-update-group [group]="patient" />
								}
							</div>
						</div>
					}
					@case ("contactway") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
								<hr />
							</div>
							<div class="panel-body">
								<app-gen-table
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									tableName="contactways"
									(changeHappened)="leftSideUpdate($event, 'contactways')"
								/>
							</div>
						</div>
					}
					@case ("diagppatient") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
							</div>
							<hr />
							<div
								class="panel-body"
								style="direction: ltr"
								dir="ltr"
							>
								<app-gen-table
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									tableName="diagppatients"
									(changeHappened)="leftSideUpdate($event, 'diagppatients')"
								/>
							</div>
						</div>
					}
					@case ("medicpatient") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
								<hr />
							</div>
							<div
								class="panel-body"
								style="direction: ltr"
								dir="ltr"
							>
								<app-gen-table
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									tableName="medicpatients"
									[dataVariables]="{ patient_id: patient.id }"
									(changeHappened)="leftSideUpdate($event, 'medicpatients')"
								>
									<div
										slot="upper-row"
										class="d-flex"
									>
										<button
											titleLang="add_prescription"
											tabindex="-1"
											class="btn btn-primary btns-with-icon"
											(click)="addPrescription()"
											preIcon="plus"
										>
											<!-- <span>{{"prescription" | langVal}}</span> -->
											{{ "prescription" | langVal }}
										</button>
									</div>
								</app-gen-table>
							</div>
						</div>

						<div class="panel panel-default">
							<div
								class="panel-body"
								style="direction: ltr"
								dir="ltr"
							>
								<app-gen-table
									#prescriptionsTable
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									tableName="prescriptions"
									isEditDisabled
								/>
							</div>
						</div>
					}
					@case ("nondrugpatient") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
								<hr />
							</div>

							<div class="panel-body">
								<app-gen-table
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									tableName="nondrugpatients"
									(changeHappened)="leftSideUpdate($event, 'nondrugpatients')"
								/>
							</div>
						</div>
					}
					@case ("labtestpatient") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
							</div>
							<hr />
							<div class="panel-body">
								<app-gen-table
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									tableName="labtestpatients"
									(changeHappened)="leftSideUpdate($event, 'labtestpatients')"
								/>
							</div>
						</div>
					}
					@case ("mixed_list") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
							</div>
							<hr />
							<div class="panel-body mixed-list">
								<app-mixed-list
									#mixedList
									[patient]="patient"
									[followup_id]="followup?.id"
								/>
							</div>
						</div>
					}
					@case ("test") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
							</div>
							<hr />
							<div class="panel-body">
								<div class="d-flex">
									<div class="gen-tbl-header-top colstat-big">
										<h4 class="gen-title">
											{{ "tests" | langVal }}
										</h4>
									</div>

									<div class="colstat-medium text-end">
										<button
											class="btn btn-primary"
											(click)="openTestManage()"
										>
											{{ "open_test_manage" | langVal }}
										</button>
									</div>
								</div>
								<div class="row">
									<div class="col-12 items">
										@if (hasCheckedItems) {
											<div
												style="background-color: #edf9eb"
												[spinner]="isSendingMulti"
											>
												<button
													class="btn btn-primary"
													(click)="sendTests()"
												>
													{{ "email_multiple_invoices" | langVal }}
												</button>
												<button
													class="btn btn-primary"
													(click)="printTests()"
												>
													{{ "print_multiple_invoices" | langVal }}
												</button>
											</div>
										}

										<div class="table-responsive">
											<table class="table table-hover">
												<thead>
													<tr>
														@if (permsService.perms?.clinical) {
															<th>
																<app-yes-no
																	(onClick)="
																		testsChooseAllChecked =
																			!testsChooseAllChecked;
																		testsChooseAll()
																	"
																	[isCheck]="testsChooseAllChecked"
																/>
															</th>
														}
														@for (field of ["date", "test"]; track field) {
															<th>{{ field | langVal }}</th>
														}
														@if (permsService.perms?.clinical) {
															@for (
																field of ["answers", "download", "graphs"];
																track field
															) {
																<th>{{ field | langVal }}</th>
															}
														}
													</tr>
												</thead>
												<tbody>
													@for (row of patient.tests; track row) {
														<tr [class.grey]="$odd">
															@if (permsService.perms?.clinical) {
																<td>
																	<app-yes-no
																		(onClick)="
																			row.checked = !row.checked;
																			postTestChoose()
																		"
																		[isCheck]="row.checked"
																	/>
																</td>
															}
															<td>{{ row.date | dateFormat }}</td>
															<td>{{ row.name }}</td>
															@if (permsService.perms?.clinical) {
																<td titleLang="testanswers">
																	@if (
																		row.is_locked == "yes" || row.html_text
																	) {
																		<app-icon
																			icon="map"
																			pointer
																			(click)="row.open = !row.open"
																		/>
																	}
																</td>
																<td>
																	<app-spin-or-content
																		[isSpin]="row?.isDownloading"
																	>
																		<app-icon
																			icon="download"
																			pointer
																			(click)="download_testfill(row)"
																		/>
																	</app-spin-or-content>
																</td>
																<td>
																	@if (row.calculated == "yes") {
																		<button
																			class="btn btn-primary"
																			(click)="openTestGraph(row)"
																		>
																			{{ "graphs" | langVal }}
																		</button>
																	}
																</td>
															}
														</tr>
														@if (row.open) {
															<tr [class.grey]="$odd">
																<td colspan="999">
																	@if (row.html_text) {
																		<div
																			[innerHTML]="row.html_text | safeHtml"
																		></div>
																	} @else {
																		<app-test-display [test]="row" />
																	}
																</td>
															</tr>
														}
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
					@case ("reminder") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
								<hr />
							</div>
							<div class="panel-body">
								<app-gen-table
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									tableName="notifications"
								/>
							</div>
						</div>
					}
					@case ("invoice") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
								<hr />
							</div>
							<div class="panel-body">
								<app-gen-table
									#finresourceTable
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									isCheckItems
									(checkedItemsChanged)="updateFinResourceIds($event)"
									tableName="finResource_list"
								>
									<div class="d-flex justify-content-between flex-wrap">
										@if (
											this.patient?.is_group != "yes" ||
											this.patient?.charge_mode == "by_group"
										) {
											<div>
												@if (permsService.perms?.administrative) {
													<button
														titleLang="invoice_short"
														tabindex="-1"
														class="btn btn-primary"
														(click)="addInvoice()"
														postIcon="plus"
													>
														{{ "invoice_short" | langVal }}
													</button>
													<button
														titleLang="bankaccounts"
														tabindex="-1"
														class="btn btn-primary"
														(click)="openBankaccounts()"
													>
														{{ "bankaccounts" | langVal }}
													</button>
												}
											</div>
										}

										<div>
											<button
												titleLang="follow_ups_report"
												tabindex="-1"
												class="btn btn-primary"
												(click)="openReport('followups_report')"
											>
												{{ "follow_ups_report" | langVal }}
											</button>
											<button
												titleLang="income"
												tabindex="-1"
												class="btn btn-primary"
												(click)="openReport('invoices_report')"
											>
												{{ "income" | langVal }}
											</button>
										</div>
										@if (
											this.patient?.is_group != "yes" ||
											this.patient?.charge_mode == "by_group"
										) {
											<div>
												<app-btn-ext
													text="debts_report"
													title="debts_report"
													[titleLang]="'debts_report'"
													(clickEv)="openReport('debts_report')"
												/>
												<app-btn-ext
													title="send_sms_proforma"
													[titleLang]="'send_sms_proforma'"
													(clickEv)="sendProforma('sms')"
													[isSpin]="isSendingProformaPhone"
													iconName="sms_small"
												/>
												<app-btn-ext
													title="send_wa_proforma"
													[titleLang]="'send_wa_proforma'"
													(clickEv)="sendProforma('whatsapp')"
													[isSpin]="isSendingProformaWhatsapp"
													iconName="whatsapp"
												/>
												<app-btn-ext
													title="send_email_proforma"
													[titleLang]="'send_email_proforma' | langVal"
													(clickEv)="sendProforma('email')"
													[isSpin]="isSendingProformaEmail"
													iconName="email"
												/>
											</div>
										}
									</div>
								</app-gen-table>
							</div>
						</div>
					}
					@case ("document") {
						<div class="panel panel-default">
							<div class="panel-head">
								<div class="col-12">
									<ng-container *ngTemplateOutlet="backBtn" />
								</div>
								<hr />
							</div>
							<div class="panel-body">
								<app-gen-table
									#documentsTable
									filterField="patient_id"
									[filterFieldValue]="patient.id"
									tableName="documents"
								>
									<div class="row">
										<div
											class="col-12"
											style="text-align: end"
										>
											<button
												titleLang="letter"
												tabindex="-1"
												class="btn btn-primary"
												(click)="addMedicalLetter()"
												postIcon="plus"
											>
												{{ "medical_letter" | langVal }}
											</button>
											<button
												titleLang="letter"
												tabindex="-1"
												class="btn btn-primary"
												(click)="addDocument()"
												postIcon="plus"
											>
												{{ "letter" | langVal }}
											</button>

											<button
												titleLang="upload_file"
												tabindex="-1"
												class="btn btn-primary"
												(click)="openUploadDocumentModal()"
												postIcon="plus"
											>
												{{ "upload_file" | langVal }}
											</button>
										</div>
									</div>
								</app-gen-table>
								<!-- (changeHappened)="leftSideUpdate($event,'documents')" -->
							</div>
						</div>
					}
					@default {
						@if (isHome) {
							@if (followup) {
								<app-home-meeting
									[followup]="followup"
									[patient]="patient"
									(moveToOtherFollowup)="moveToOtherFollowup.emit($event)"
									(addDocumentEv)="addMedicalLetter()"
									(addInvoiceEv)="addInvoice()"
									(openUploadDocumentModalEv)="openUploadDocumentModal()"
								/>
							}
						} @else {
							<div>
								@if (patient.is_group == "no") {
									<app-update-patient
										[patient]="patient"
										(addDocumentEv)="addDocument()"
										(addInvoiceEv)="addInvoice()"
										(openUploadDocumentModalEv)="openUploadDocumentModal()"
									/>
								} @else {
									<app-update-group [group]="patient" />
								}
							</div>
						}
					}
				}
			}
		</div>

		@if (!isLoading && patient) {
			<div
				[hidden]="
					eventsService.isMobile &&
					(showColumn | inArray: ['calendar', 'center'])
				"
				id="home-side-patient-col"
				class="col-12 col-lg-4 side-scroll"
			>
				@for (sideItem of sideItems; track sideItem) {
					<app-person-side-item
						[color]="color"
						[sideItem]="sideItem"
						[curCenter]="curCenter"
						(click)="sideItemClick(sideItem.name)"
						class="pointer"
					>
						<div class="person-side-items">
							@switch (sideItem.name) {
								@case ("details") {
									@if (patient?.is_group == "yes") {
										<div
											class="row form-group"
											(click)="$event.stopImmediatePropagation()"
										></div>
									}
								}
								@case ("tasks") {
									<div *ngFor="let task of patient.tasks | slice: 0 : 4">
										{{ task.content }}
									</div>
								}
								@case ("contactway") {
									<div
										class="contactway"
										*ngFor="
											let contactway of patient.contactways | slice: 0 : 4
										"
									>
										{{ contactway.contactway }}
										{{ contactway.name ? "   -   " + contactway.name : "" }}
									</div>
								}
								@case ("medicpatient") {
									<div
										*ngFor="
											let medicpatient of patient.medicpatients
												| endDateNull
												| slice: 0 : 4
										"
										style="direction: ltr"
									>
										{{ medicpatient.medic_name_dec || medicpatient.medic_name }}
										{{
											medicpatient.amount +
												"X" +
												medicpatient.frequency +
												"/" +
												medicpatient.frequency_unit
										}}
									</div>
								}
								@case ("nondrugpatient") {
									<div
										*ngFor="
											let nondrugpatient of patient.nondrugpatients
												| endDateNull
												| slice: 0 : 4
										"
										style="direction: ltr"
									>
										{{
											nondrugpatient.nondrug_name_dec ||
												nondrugpatient.nondrug_name
										}}
									</div>
								}
								@case ("diagppatient") {
									<div
										*ngFor="
											let diagppatient of patient.diagppatients
												| endDateNull
												| slice: 0 : 8
										"
										style="direction: ltr"
									>
										{{ diagppatient.diagp_name_dec || diagppatient.diagp_name }}
									</div>
								}
								@case ("labtestpatient") {
									<div
										*ngFor="
											let labtestpatient of patient.labtestpatients
												| slice: 0 : 4
										"
										style="direction: ltr"
									>
										{{
											labtestpatient.labtest_name_dec ||
												labtestpatient.labtest_name
										}}
									</div>
								}
								@case ("mixed_list") {
									<div>
										{{ ("last_meeting" | langVal) + ": " }}
										{{ patient.last_followup }}
									</div>
									<div>
										{{ ("next_meeting" | langVal) + ": " }}
										{{ patient.next_followup }}
									</div>
								}
								@case ("reminder") {
									<div
										*ngFor="
											let notification of patient.notifications | slice: 0 : 4
										"
									>
										{{ notification.message_dec || notification.message }}
									</div>
								}
								@case ("test") {
									<div
										*ngFor="let test of patient.tests | slice: 0 : 4"
										class="document-div row"
									>
										<div class="col-9">{{ test.name }}</div>
										<div class="col text-end">{{ test.date | dateFormat }}</div>
									</div>
								}
								@case ("invoice") {
									@if (
										this.patient?.is_group != "yes" ||
										this.patient?.charge_mode == "by_group"
									) {
										<div class="totals-format">
											<div>
												{{
													("total_lm_prices" | langVal) +
														" " +
														(lastMonth | langVal) +
														": "
												}}
												<span>{{ patient.total_lm_prices | finNumber }}</span>
											</div>
											@if (patient.payor_id) {
												<div>
													{{
														("total_lm_payor_prices" | langVal) +
															" " +
															(lastMonth | langVal) +
															": "
													}}
													<span>{{
														patient.total_lm_payor_prices | finNumber
													}}</span>
												</div>
											}
											<div>
												{{
													("total_until_lm_debt" | langVal) +
														" " +
														(lastMonth | langVal) +
														": "
												}}
												<span>{{
													patient.total_until_lm_debt < 0
														? 0
														: (patient.total_until_lm_debt | finNumber)
												}}</span>
											</div>
											@if (patient.total_total_debt >= 0) {
												<div>
													{{ ("total_total_debt" | langVal) + ": " }}
													<span>{{
														patient.total_total_debt | finNumber
													}}</span>
												</div>
											} @else {
												<div>
													{{
														("total_total_credit" | langVal) +
															patient.name +
															": "
													}}
													<span>{{
														-patient.total_total_debt | finNumber
													}}</span>
												</div>
											}
										</div>
									}
								}
								@case ("document") {
									<div
										*ngFor="let document of patient.documents | slice: 0 : 4"
										class="document-div row"
									>
										<div class="col-9">
											{{ document.title_dec || document.title }}
										</div>
										<div class="col text-end">
											{{ document.date | dateFormat }}
										</div>
									</div>
								}
							}
						</div>
					</app-person-side-item>

					<!-- [style.direction]="sideItem.name=='medicpatient' || sideItem.name=='nondrugpatient'?'ltr':''"
                        [style.align]="sideItem.name=='medicpatient' || sideItem.name=='nondrugpatient'?'left !important':'right'" -->
				}
			</div>
		}
	</div>

	<ng-template #backBtn>
		<button
			class="close back-to"
			tabindex="-1"
			[titleLang]="
				(isHome ? 'back_to_journal' : 'back_to_patient_details') | langVal
			"
			(click)="backToMain()"
			preIcon="modal-close"
		>
			<span class="back-to-details">{{
				(isHome ? "back_to_journal" : "back_to_patient_details") | langVal
			}}</span>
		</button>
	</ng-template>
}
