<div
	#container
	class="calendar-wrap"
	[class.printScreenMode]="isPrintScreenModeActive()"
	[class.OwnerHasUsers]="permsService?.owner_has_users"
	[class.is-guest]="isGuest"
	[class.short-meetings-calendar]="shortMeetingsCalendar"
>
	<!-- style="min-width:1000px;"-->

	@if (firstChangeHappened) {
		@if (isHome) {
			<div class="calendar-above-row home-vers">
				<div>
					<button
						class="cal-btn today-btn"
						(click)="calMove('today')"
					>
						{{ "today" | langVal }}
					</button>
				</div>
				<div>
					<button
						class="cal-btn cal-icon"
						(click)="calMove('prev')"
						icon="rightArrow"
					></button>
					<div class="cal-title">{{ periodTitle }}</div>
					<button
						class="cal-btn cal-icon"
						(click)="calMove('next')"
						icon="leftArrow"
					></button>
				</div>
				<div>
					<app-date-picker
						#datePicker
						title_ph_lang="date"
						(changeEvent)="goToDate($event)"
						[addonObject]="{ after: { icon: 'calendar' } }"
						isButtonView
					>
					</app-date-picker>
				</div>
			</div>
		} @else {
			<div class="calendar-above-row cal-vers">
				<div class="d-flex align-items-center gap-1 gap-sm-2">
					<button
						class="cal-btn cal-icon"
						(click)="calMove('prev')"
						icon="rightArrow"
					></button>
					<button
						class="cal-btn d-none d-sm-block"
						(click)="calMove('today')"
					>
						{{ currentViewLangVal() | langVal }}
					</button>

					<div class="cal-title order-lg-5">{{ periodTitle }}</div>
					<button
						class="cal-btn cal-icon"
						(click)="calMove('next')"
						icon="leftArrow"
					></button>
					<div class="no-mob order-last">
						<app-date-picker
							#datePicker
							title_ph_lang="date"
							(changeEvent)="goToDate($event)"
							isButtonView
							[addonObject]="{ after: { icon: 'calendar' } }"
						/>
					</div>
				</div>

				@if (permsService?.owner_has_users) {
					<div class="end-or-center no-small-mob">
						<!--   && permsService?.perms?.all_patients_all_fus -->
						<div>
							<app-autocomplete-with-tags
								(chosenEvent)="chooseTagsForLocations($event)"
								title_ph_lang="filter_locations"
								isLocationLimited
							/>
						</div>
						@if (permsService?.perms?.all_patients_all_fus) {
							<div
								class="d-none d-xxl-block"
								style="margin-inline-start: 20px"
							>
								<app-autocomplete-with-tags
									(chosenEvent)="chooseTagsForUsers($event)"
									title_ph_lang="filter_sub_users"
								/>
							</div>
						}
					</div>
				}

				<div class="d-flex">
					@if (!isGuest) {
						<div class="d-flex">
							<div style="width: fit-content">
								<!-- || !permsService?.owner_has_users-->

								@if (permsService.perms?.administrative) {
									<button
										class="cal-btn no-mob"
										titleLang="followups"
										tabindex="-1"
										(click)="openFollowupsList()"
										lang
									>
										followups
									</button>
								}
								@if (
									permsService?.owner_has_users &&
									permsService.perms?.administrative
								) {
									<button
										class="cal-btn d-none d-xxxl-inline-block"
										titleLang="time_allocation"
										tabindex="-1"
										routerLink="/time-allocation/"
									>
										{{ "time_allocation" | langVal }}
									</button>
								}
							</div>
							<div
								class="no-mob"
								style="width: 50px"
							>
								<button
									class="cal-btn cal-icon"
									(click)="openMeetingTimes()"
									icon="magic"
								></button>
							</div>

							@if (currentView() == "resourceTimeGridDay") {
								<div class="no-mob pointer">
									<div
										class="cal-btn cal-icon"
										[class.active]="printScreenMode"
										(click)="
											printScreenMode = !printScreenMode;
											getFollowupseriesFromStore()
										"
										icon="print"
									></div>
								</div>
							}
						</div>
					}

					@if (
						permsService?.owner_has_users &&
						permsService?.perms?.all_patients_all_fus
					) {
						<div class="d-none d-xxl-flex gap-2 px-3">
							<button
								class="cal-btn cal-icon"
								(click)="subUserMove(-1)"
								icon="rightArrow"
							></button>

							<app-gen-autocomplete
								#subUsersFuzzy
								title_ph_lang="sub_user"
								style="width: 135px"
								[fuzzySearchableClasses]="['sub_users']"
								[approvedIds]="userTagsObj.ids"
								(chosenEvent)="subUsersClick($event)"
							/>
							<button
								class="cal-btn cal-icon"
								(click)="subUserMove(1)"
								icon="leftArrow"
							></button>
						</div>
					}

					<!--        @if(currentView()=='resourceTimeGridDay'){-->
					<!--          <div class="no-mob pointer" icon="print" titleLang="print" (click)="printScreenMode=!printScreenMode;getFollowupseriesFromStore()"></div>-->
					<!--        }-->

					<div class="d-flex">
						<div class="cal-btns-mobile">
							<button
								class="cal-btn"
								[class.active]="urlView == 'day'"
								(click)="
									changeView(
										permsService?.owner_has_users
											? 'resourceTimeGridDay'
											: 'timeGridDay'
									)
								"
							>
								{{ "day" | langVal }}
							</button>
							<button
								class="cal-btn"
								[class.active]="urlView == 'week'"
								(click)="changeView('timeGridWeek')"
							>
								{{ "week" | langVal }}
							</button>
							<button
								class="cal-btn"
								[class.active]="urlView == 'month'"
								(click)="changeView('dayGridMonth')"
							>
								{{ "month" | langVal }}
							</button>
						</div>
					</div>
				</div>
			</div>
		}
	}

	<full-calendar
		#calendar
		deepChangeDetection="true"
		[options]="calendarOptions"
	/>
</div>

@if (hoverObj) {
	<div
		class="tooltipevent"
		[style.top]="hoverObj.y + 'px'"
		[style.left]="hoverObj.x + 'px'"
	>
		@if (hoverObj?.dispObj?.is_meeting_request) {
			<h4 class="flex-sp-bet">
				<div>
					{{ hoverObj.dispObj.title }}
				</div>
			</h4>
			<div
				class="flex-sp-bet"
				style="font-size: 12px"
			>
				<div>{{ hoverObj.dispObj.datePart }}</div>
				<span class="text-muted"
					>{{
						(hoverObj.dispObj.isNew == "yes" ? "new" : "returning") | langVal
					}}
				</span>
			</div>
			<div>{{ hoverObj.dispObj.remarks }}</div>
		} @else {
			<h4 class="flex-sp-bet">
				<div [preIcon]="hoverObj.dispObj.iconCls">
					{{ hoverObj.dispObj.title }}
				</div>
				<div style="font-size: 14px; color: #777">
					<span> {{ hoverObj.dispObj.show_id }} </span>
					@if (hoverObj.dispObj.seriesIcon) {
						<span icon="repeat"></span>
					}
				</div>
			</h4>
			<div
				class="flex-sp-bet"
				style="font-size: 12px"
			>
				<div>{{ hoverObj.dispObj.datePart }}</div>
				@if (!hoverObj.dispObj.isVacation) {
					<div style="display: inline-block">
						<span class="text-muted">{{ hoverObj.dispObj.minutes }}</span>
						{{ "minutes" | langVal }}
					</div>
				}
			</div>
			<div>{{ hoverObj.dispObj.notes }}</div>
			@if (hoverObj.dispObj.followup_description) {
				<div>{{ hoverObj.dispObj.followup_description }}</div>
			}
			@if (hoverObj.dispObj.assocUserNames) {
				<div>
					<b> {{ hoverObj.dispObj.assocUserNames }}</b>
				</div>
			}
			@if (hoverObj.dispObj.location) {
				<div>
					<b> {{ hoverObj.dispObj.location }}</b>
				</div>
			}
			@if (hoverObj.dispObj?.patientRemarks) {
				<div [innerHtml]="hoverObj.dispObj.patientRemarks | safeHtml"></div>
			}
		}
	</div>
}
