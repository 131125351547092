@if (apiService.isStarted) {
	@if (apiService.isUserLoggedIn) {
		<app-menu />
	}
	<div [class.router-cont]="apiService.isUserLoggedIn">
		<router-outlet />
	</div>
	@if (apiService.isUserLoggedIn) {
		<app-footer />
	}
} @else {
	<div
		class="overlay-spinner"
		spin
	></div>
}
<!-- end of normal presentation -->
<app-toast />
<app-gen-modal />
<app-multi-modals />
<app-toast-download />

@if (store.timeToRefreshMessage) {
	<div class="refresh">
		<app-icon
			icon="close"
			pointer
			(click)="store.timeToRefreshMessage = false"
		/>
		{{ "time_to_refresh" | langVal }}
	</div>
}

@if (hasHelp) {
	<div
		class="fixed-corner help-btn"
		(click)="openHelp()"
		titleLang="report_problem"
	>
		<p>?</p>
	</div>
}
@if (networkOffline) {
	<div class="network-offline">{{ "network_error" | langVal }}</div>
}
<!-- @if(!apiService.isUserLoggedIn){
  <div class="fixed-corner app-lang-select">
   <select (change)="langChanged()" [(ngModel)]="lang.langCode">
     <option value="iw">עברית</option>
     <option value="en">English</option>
   </select>
  </div>
} -->

<!-- @if(!hasApprovedCookies){
  <div class="approve-cookies">
    <div>Please approve cookies</div>
    <button (click)="approveCookies()">🐱</button>
  </div>
} -->
