import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from "@angular/core"
import { ZoneRow } from "@app/definitions/types"
import { CcParamsGen } from "@app/cc-params-gen/cc-params-gen"

@Component({
	selector: "app-cc-params-public-tests",
	template: `
		<div>
			<h4 class="gen-title">{{ "public_tests" | langVal }}</h4>
			<div>
				<app-table-wrap fieldsStr="use_this,name">
					@for (row of rows; track row.id) {
						<tr>
							<td style="width: 100px;">
								<app-yes-no
									(onClick)="show(row)"
									[isCheck]="row.show"
								/>
							</td>
							<td>{{ row.name }}</td>
						</tr>
					}
				</app-table-wrap>
			</div>
		</div>
	`,
})
export class CcParamsPublicTestsComponent extends CcParamsGen {
	//inheriting most functions and fields!
	rows: any[] = []
	ngOnInit() {
		this.rows = []
		const ccPublicTests = this.store.getCliniqDataOrExit()?.public_tests
		const pts = this.store
			.getSiteDataTable("tests")
			.filter((it) => it.public === "yes")
		if (ccPublicTests) {
			ccPublicTests.forEach((id) => {
				this.rows.push({
					id,
					name: pts.find((it) => it.id == id)?.name,
					show: true,
				})
			})
		}
		pts.forEach((test) => {
			if (this.rows.find((row) => row.id == test.id)) {
				return
			}
			this.rows.push({ id: test.id, name: test.name, show: false })
		})
		///dsfsdfsdf
	}
	show(row: any) {
		row.show = !row.show
		this.save()
	}
	async save() {
		const cc = this.store.getCliniqDataOrExit()
		cc.public_tests = this.rows.filter((row) => row.show).map((row) => row.id)

		const result: any = await this.apiService.post("update_configcliniq_data", {
			...cc,
			update: "user",
		})
		if (result && !result?.error) {
			//success
			this.modalService.openToast(this.lang.getVal("updated_successfully")) //open toast with success message
			sessionStorage.setItem("cliniqData", JSON.stringify(result)) //update the config object in the sessionStorage
		} else {
			//fail
			this.modalService.openToast(this.lang.getVal(result?.error)) //open toast with fail message
		}
	}
}
