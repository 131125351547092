import { Component, Input } from "@angular/core"
import {
	insertPatientFields,
	reportNames,
	reportNamesSolo,
} from "@app/definitions/types"
import { ApiService } from "@app/services/api.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { PermsService } from "@app/services/perms.service"
import { StoreService } from "@app/services/store.service"
import { Subject, debounceTime } from "rxjs"

@Component({
	selector: "app-cc-checkboxes",
	template: `
		<div class="row checkboxes">
			<div class="col-12">
				<h3>{{ title | langVal }}</h3>
				<div class="table-responsive">
					<table class="table table-hover">
						<tbody>
							<tr>
								<td>
									<input
										[checked]="allOp"
										(change)="flipAll()"
										type="checkbox"
									/>
								</td>
								<td>
									<span (click)="flipAll()">{{
										"select_all_fields" | langVal
									}}</span>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<hr />
								</td>
							</tr>
							@for (field of fields; track field) {
								<tr>
									<td>
										<input
											[checked]="saveObj[field]"
											(change)="
												saveObj[field] = $event.target.checked; saveSubj.next(1)
											"
											type="checkbox"
										/>
									</td>
									<td>
										<span
											(click)="
												saveObj[field] = !saveObj[field]; saveSubj.next(1)
											"
											>{{ field | langVal }}</span
										>
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	`,
})
export class CcCheckboxesComponent {
	@Input() fields: string[] = []
	@Input() title: string = ""
	@Input() ccFieldName: string = ""

	saveObj: any = {}
	allOp: boolean = true
	isSaving: boolean = false
	saveSubj = new Subject()

	constructor(
		protected store: StoreService,
		protected apiService: ApiService,
		public lang: LangService,
		public modalService: ModalService,
		protected perms: PermsService
	) {}

	ngOnInit() {
		if (this.ccFieldName == "reports") {
			this.fields = this.perms.owner_has_users ? reportNames : reportNamesSolo
		}
		this.saveObj = this.store.getCliniqDataOrExit()[this.ccFieldName]
		if (!this.saveObj) {
			this.saveObj = this.fields.reduce(
				(acc, item) => ({ ...acc, [item]: true }),
				{}
			)
		}
		this.saveSubj.pipe(debounceTime(800)).subscribe(() => {
			this.save()
		})
	}

	async save() {
		const cc = this.store.getCliniqDataOrExit()
		cc[this.ccFieldName] = this.saveObj

		this.isSaving = true
		const result: any = await this.apiService.post("update_configcliniq_data", {
			...cc,
			update: "user",
		})
		this.isSaving = false
		if (result && !result?.error) {
			//success
			this.modalService.openToast(this.lang.getVal("updated_successfully")) //open toast with success message
			sessionStorage.setItem("cliniqData", JSON.stringify(result)) //update the config object in the sessionStorage
			if (this.ccFieldName == "reports") {
				this.store.hiddenMenuItemsSubj.next(true)
			}
		} else {
			//fail
			this.modalService.openToast(this.lang.getVal(result?.error)) //open toast with fail message
		}
	}

	flipAll() {
		this.allOp = !this.allOp
		Object.keys(this.saveObj).forEach((k) => (this.saveObj[k] = this.allOp))
		this.saveSubj.next(1)
	}
}

@Component({
	selector: "app-cc-insert-patient-templates",
	template: `
		<app-cc-checkboxes
			title="select_fields"
			[fields]="fields"
			ccFieldName="insert_patient_template"
		/>
	`,
})
export class CcInsertPatientTemplate {
	fields: string[] = insertPatientFields
}

@Component({
	selector: "app-cc-reports",
	template: `
		<app-cc-checkboxes
			title="report_selection"
			[fields]="fields"
			ccFieldName="reports"
		/>
	`,
})
export class CcReports {
	fields: string[] = reportNames
}
