@if (isBlocked) {
	{{ "not_allowed" | langVal }}
} @else {
	<h3>{{ headerLang | langVal }}</h3>
	<h4>{{ "files_to_import1" | langVal }}</h4>
	<h4>{{ "files_to_import2" | langVal }}</h4>
	<h4>{{ "files_to_import3" | langVal }}</h4>
	@if (hasWarning) {
		<h4>{{ "import_warning" | langVal }}</h4>
	}
	<div class="row imp-p">
		<div class="col-12 col-md-2">
			<label>{{ "upload_file" | langVal }}</label>

			<app-gen-file-input
				fileType="xlsx-csv"
				title_ph_lang="upload_file"
				(changeEvent)="uplFile($event)"
				[isUploading]="isUploadingXlsx"
			/>
		</div>
	</div>
	@if (error) {
		<p class="error">{{ error | langVal }}</p>
	}
	@if (hasDate) {
		<div class="row">
			<div class="col-12">
				<label>{{ "date_format" | langVal }}</label>
				<app-gen-button-group
					[actualObj]="this"
					fieldName="dateFormat"
					title_ph_lang="date_format"
					optionsListName="import_date_opts"
				></app-gen-button-group>
			</div>
		</div>
	}
	@if (table.length) {
		<div class="row imp-p">
			<div
				class="col-lg-12 parent"
				style="overflow: auto"
			>
				<div class="child">
					<table class="table table-striped table-hover">
						<thead>
							<tr>
								<th></th>
								@for (col of columns | keyvalue; track col.key) {
									<th>
										<select
											[(ngModel)]="columns[col.key]"
											(change)="colChosen()"
										>
											@for (field of fields; track field) {
												<option [value]="field">{{ field | langVal }}</option>
											}
										</select>
									</th>
								}
							</tr>
						</thead>
						<tbody>
							@for (row of table; track row) {
								<tr>
									<td>
										<button
											(click)="deleteRow($index)"
											class="btn btn-danger"
										>
											{{ "delete" | langVal }}
										</button>
									</td>
									@for (cell of row; track cell) {
										<td>{{ cell }}</td>
									}
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	}
	@if (error) {
		<p class="error">{{ error | langVal }}</p>
	}
	<div
		class="modal-btns"
		style="padding: 20px"
	>
		<app-btn-ext
			text="import"
			[disabled]="!isFormSubmittable"
			tabindex="1"
			(clickEv)="import()"
			[isSpin]="isSaving"
		/>
	</div>
}
