import {
	Component,
	EventEmitter,
	Input,
	Output,
	booleanAttribute,
} from "@angular/core"
import { Modalable } from "@app/Modalable"

@Component({
	selector: "app-testanswers-fill",
	templateUrl: "./testanswers-fill.component.html",
})
export class TestanswersFillComponent extends Modalable {
	modalTitleLang: string = "testanswers_fill"
	modalWidth: string = "modal-800"

	isSaving: boolean = false
	isSendingInvite: boolean = false

	@Input() test_id: number = 0
	@Input() patient_id: number = 0
	@Input({ transform: booleanAttribute }) inUserPatientMode: boolean = false
	@Output() onFinish = new EventEmitter()
	test: any = null
	patient: any = null
	isFormSubmittable: boolean = false
	user_sign_img_blob: any = null
	userSignErr: string = ""
	patient_sign_img_blob: any = null
	patientSignErr: string = ""
	in_followup: boolean = false

	ngOnInit() {
		if (this.patient_id) {
			this.patient = this.store.searchSiteDataItem(this.patient_id, [
				"patients",
			])
		}
		this.test = this.store.searchSiteDataItem(this.test_id, ["tests"])
		this.test.tests = this.store
			.getSiteDataTable("tests")
			.filter((tq) => tq.test_id == this.test_id)
		this.test.tests.sort((a: any, b: any) => a.order_num - b.order_num)
		if (this.test.tests.length) {
			this.test.tests.forEach((t) => this.prepQustionsForTest(t))
		} else {
			this.prepQustionsForTest(this.test)
		}
	}
	prepQustionsForTest(test: any) {
		test.questions = this.store
			.getSiteDataTable("testquestions")
			.filter((tq) => tq.test_id == test.id)
		test.questions.sort((a: any, b: any) => a.order_num - b.order_num)
		test.questions.forEach((tq) => {
			//tq.mark = null
			tq.answer = tq.field_type == "number" ? 0 : ""
			tq.errors = {}
			if (tq.options?.length) {
				const opts = JSON.parse(tq.options)
				tq.optionsArr = opts
				// .map((op, pos) => ({
				// 	...op,
				// 	id: pos + 1,
				// 	name: op.value,
				// }))
			}
		})
	}

	validateEntireForm() {
		this.isFormSubmittable = true
		if (this.test.tests.length) {
			this.test.tests.forEach((t) => this.validateQuestions(t))
		} else {
			this.validateQuestions(this.test)
		}
		if (this.test?.user_sign_required == "yes") {
			this.userSignErr = ""
			if (!this.user_sign_img_blob) {
				this.userSignErr = this.lang.getVal("test_should_be_signed")
				this.isFormSubmittable = false
			}
		}
		if (this.test?.patient_sign_required == "yes") {
			this.patientSignErr = ""
			if (!this.patient_sign_img_blob) {
				this.patientSignErr = this.lang.getVal("test_should_be_signed")
				this.isFormSubmittable = false
			}
		}
	}
	validateQuestions(test: any) {
		for (let tq of test.questions) {
			if (tq.required == "no") {
				continue
			}
			tq.errors = {}
			const validationRules: any = { answer: { not_empty: true }, mark: {} }
			if (tq.field_type === "number") {
				validationRules.answer = { not_empty: true, is_integer: true }
				if (tq.range_min) {
					validationRules.answer.larger_than = Number(tq.range_min) - 1
					validationRules.answer.smaller_than = Number(tq.range_max) + 1
				}
			}
			const isOk = this.validator.validateEntireForm(
				tq,
				tq.errors,
				validationRules
			)
			if (!isOk) {
				this.isFormSubmittable = false
			}
		}
	}

	saveUserPatient() {
		const sendObj: any = { ...this.test }
		if (this.test?.user_sign_required == "yes") {
			sendObj.user_sign_img_blob = this.user_sign_img_blob
		}
		if (this.test?.patient_sign_required == "yes") {
			sendObj.patient_sign_img_blob = this.patient_sign_img_blob
		}
		this.close(sendObj)
		this.onFinish.emit(sendObj)
	}

	async save() {
		this.isSaving = true

		const sendObj: any = {
			...this.test,
			patient_id: this.patient_id,
			in_followup: this.in_followup,
		}
		if (this.test?.user_sign_required == "yes") {
			sendObj.user_sign_img_blob = this.user_sign_img_blob
		}
		if (this.test?.patient_sign_required == "yes") {
			sendObj.patient_sign_img_blob = this.patient_sign_img_blob
		}
		await this.store.downloadToastFunc(async () => {
			const resPdf = await this.apiService.download("answer_test", sendObj)
			if (!resPdf?.size) {
				return this.modalService.openToast(this.lang.getVal("save_failed"))
			}
			this.apiService.downloadFile(resPdf, this.lang.getVal("test") + ".pdf")
		})
		this.modalService.openToast(this.lang.getVal("all_answers_filled_saved"))
		this.isSaving = false

		let returnText = this.getTestText(this.test)
		this.store.detectMixedListChangeNeeded("testfills", this.patient_id)

		this.close(returnText)
		this.onFinish.emit(returnText)
	}
	getTestText(test: any) {
		let retText =
			"<span class='fw-bold  text-decoration-underline'>" +
			test.name +
			"</span> <br />"
		if (test?.tests?.length > 1) {
			test.tests.forEach((t) => {
				retText += this.getTestText(t)
			})
		}
		if (test?.questions?.length) {
			test.questions.forEach((tq) => {
				retText +=
					"<div style='font-weight: 700'>" +
					tq.question +
					"</div><div style='font-weight: 500; padding-bottom: 10px'>" +
					tq.answer +
					"</div>"
			})
		}
		return retText
	}
	async openScheduler() {
		await this.modalService.openMulti("test-scheduler", {
			patient_id: this.patient.id,
			test_id: this.test_id,
		})
		this.close(true)
	}
	async send_invite() {
		this.isSendingInvite = true
		await this.store.patientSendInvite(this.patient.id, [this.test_id])
		this.isSendingInvite = false
		this.close(true)
	}
	// sendInviteWa(){
	//   this.store.patientSendInviteWa(this.patient.id,[this.test_id]);
	// }
	onCheck(tq: any, opt: any) {
		tq.answer = opt.value
		if (this.test.calculated === "yes") {
			tq.mark = opt.mark
		}
		this.validateEntireForm()
	}
	onBtnGroupClick(tq: any) {
		if (this.test.calculated === "yes") {
			const opt = tq.optionsArr.find((it) => it.value === tq.answer)
			tq.mark = opt?.mark ?? null
		}
		this.validateEntireForm()
	}
}
