import {
	FinResourceType,
	Followupserie,
	GenTableUpdate,
	GenTableUpdateField,
	PatientOrUserId,
	PatientTestObj,
	TagsObj,
	Task,
} from "./../definitions/types"
import { ErrorService } from "./error-service.service"
import { LangService } from "./lang.service"
import { ApiService, pluck } from "@app/services/api.service"
import { Injectable } from "@angular/core"
import {
	ConfigDefinitions,
	FollowupserieSave,
	Store,
	StoreFollowup,
	StoreFollowupserie,
	StorePatient,
	StoreSiteData,
	TableGen,
} from "../definitions/types"
import * as moment from "moment"
import { EMPTY, Subject, of, from } from "rxjs"
import { catchError, map } from "rxjs/operators"
import { Router } from "@angular/router"
import { PermsService } from "./perms.service"
import { ModalService } from "./modal.service"
import { environment } from "@env/environment"
import { OptionsList } from "@app/definitions/opts"
import { EventsService } from "@app/services/events.service"

const nums = {
	f: ["אחת", "שתי", "שלוש", "ארבע", "חמש", "שש", "שבע", "שמונה", "תשע", "עשר"],
	m: [
		"אחד",
		"שני",
		"שלושה",
		"ארבעה",
		"חמישה",
		"שישה",
		"שבעה",
		"שמונה",
		"תשעה",
		"עשרה",
	],
	mult: [
		"",
		"",
		"עשרים",
		"שלושים",
		"ארבעים",
		"חמישים",
		"שישים",
		"שבעים",
		"שמונים",
		"תשעים",
	],
}

@Injectable({
	providedIn: "root",
})
export class StoreService {
	VERSION: string = "1.5.5"
	deferredPrompt: any = null

	data: Store = new Store()
	todayArr: StoreFollowupserie[] = []
	todayCalendarCallHappened: boolean = false
	fusesLoaded: boolean = false
	fusesLoadedFull: boolean = false
	patientPrevNextSub = new Subject<any>()
	patientMiscSub = new Subject<any>()
	patientCollectionsSub = new Subject<any>()
	finresouecrActionSubj = new Subject<any>()
	hiddenMenuItemsSubj = new Subject<any>()
	lastUpdateTime: string = null
	lastFusChangeInter: any = null
	timeToRefreshMessage: boolean = false
	calendarChangesSubj = new Subject()
	refreshTableSubject: any = new Subject()
	reInitTableSubject: any = new Subject()
	updateTableSubject: any = new Subject()
	calendarOpenMeetingSubject: any = new Subject()
	calendarOpenMeeting2Subject: any = new Subject()
	lockedPatientsSubject: any = new Subject()
	downloadSubject: any = new Subject()
	meeting_requests: any[] = []

	numberFormatObj = new Intl.NumberFormat("he-IL", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
	isOpeningAddInvoice: boolean = false
	isMobile: boolean = false
	whatsappUrl: string = ""
	lastCalView: string = "week"

	apiLink: string = ""

	unreadTasks: number = null
	// changedTasksSubj = new Subject();

	cachedPeriods: any = { dayGridMonth: {}, timeGridWeek: {}, timeGridDay: {} }

	mixedListRelatedTableNames: string[] = [
		"diagppatients",
		"medicpatients",
		"labtestpatients",
		"nondrugpatients",
		"followups",
		"testfills",
	]
	finResourceListRelatedTableNames: string[] = [
		"invoices",
		"proformas",
		"justinvoices",
		"receipts",
		"credit_invoices",
		"credit_justinvoices",
		"credit_receipts",
	]

	totalFields: string[] = [
		"total_lm_debt",
		"total_lm_prices",
		"total_monthly_debt",
		"total_total_debt",
		"total_until_lm_debt",
		"total_yearly_debt",
	]

	ccForGuestCal: any = null

	constructor(
		protected apiService: ApiService,
		private lang: LangService,
		private errorService: ErrorService,
		public router: Router,
		private permsService: PermsService,
		private modalService: ModalService
	) {
		this.loadLocalSiteData()
	}

	authGuard(state: any) {
		if (!sessionStorage.getItem("cliniqData")) {
			//if cliniqData does not exist
			if (state.url !== "/home") {
				this.apiService.postLogin = state.url //remember the url (after login will attempt to redirect to it)
			}
			this.router.navigateByUrl("/login") //go to login page
			return false
		}
		return this.verifyConfigCliniqIsIn() //cliniqData exist - refetch from server to verify
	}
	isSuperAdmin() {
		return this.getCliniqDataOrExit()?.role == 2
	}

	//API
	async verifyFuzzyIsIn() {
		const res: any = await this.apiService.post("fuzzy_search")
		this.loadSiteData(res)
		sessionStorage.setItem("siteData", JSON.stringify(res))
	}

	//HELPERS
	setSessionSiteData() {
		sessionStorage.setItem("siteData", JSON.stringify(this.data.siteData))
	}
	async loadLocalSiteData() {
		const siteData = sessionStorage.getItem("siteData")
		if (siteData) {
			this.loadSiteData(JSON.parse(siteData))
			await this.timeout(3000)
			if (
				!sessionStorage.getItem("isPatientLoggedIn") &&
				this.apiService.isUserLoggedIn
			) {
				const res = await this.apiService.post("get_patients_for_site_data")
				this.data.siteData.patients = this.removeDecArr(res.patients)
				this.setSessionSiteData()
			}
		}
	}
	normalizeHtml(text: string, maxLen: number = null) {
		text = text
			.replace(/&nbsp;/g, " ")
			.replace(/<\/div>/g, " ")
			.replace(/<\/p>/g, " ")
			.replace(/<\/li>/g, " ")
		text = this.strip_tags(text)
		if (maxLen) {
			text = text.substr(0, maxLen)
		}
		return text
	}
	strip_tags(str: string, toSpace: boolean = false) {
		str = str.toString()
		return str.replace(/<\/?[^>]+>/gi, toSpace ? " " : "")
	}
	getCollectionInPatient(tableName: string, id: number) {
		const patient = this.data.loadedPatients.find((patient) => patient.id == id)
		if (patient?.[tableName]) {
			return patient?.[tableName]
		}
	}
	removeDecItem(item: any) {
		if (!item) {
			return item
		}
		Object.keys(item).forEach((key) => {
			if (item[key] === undefined) {
				return
			}
			if (item[key + "_dec"] !== undefined) {
				// && item[key + "_dec"]
				item[key] = item[key + "_dec"]
				delete item[key + "_dec"]
			}
		})
		return item
	}
	removeDecArr(arr: any[]) {
		arr.forEach((item) => this.removeDecItem(item))
		return arr
	}
	removeDecPatient(patient: StorePatient) {
		this.removeDecItem(patient)
		Object.keys(patient).forEach((key) => {
			if (Array.isArray(patient[key])) {
				this.removeDecArr(patient[key])
			}
		})
	}
	removeDecSiteData() {
		Object.keys(this.data.siteData).forEach((key) =>
			this.removeDecArr(this.data.siteData[key])
		)
	}

	getDaysDiff(ts: number) {
		const today = moment.utc().startOf("day")
		let res = ""
		if (ts) {
			const prev = moment.utc(ts * 1000).startOf("day")
			res = `${this.getMomentDisplay(prev)} (${Math.abs(prev.diff(today, "days"))}  d)`
		}
		return res
	}
	postFusChangeForPrevNext(fus: StoreFollowupserie) {
		if (!fus) {
			return
		}
		if (fus.followuptype_id == 5) {
			this.refreshAllPatientsPrevNext()
		} else if (fus.patient_id) {
			this.refreshPatientPrevNext(fus.patient_id)
		}
	}
	async detectMixedListChangeNeeded(tableName: string, patientId: number) {
		if (!this.mixedListRelatedTableNames.includes(tableName)) {
			return
		}
		const patient = this.data.loadedPatients.find(
			(patient) => patient.id == patientId
		)
		if (!patient) {
			return
		}

		const res: any = await this.apiService.get_gen_items("mixed_list", {
			filterFieldValue: patientId,
		})
		this.updatePatientMixedList(patientId, res.mixed_list)
	}
	async detectFinResourceListChangeNeeded(
		tableName: string,
		patientId: number
	) {
		if (!patientId) {
			return
		}
		if (!this.finResourceListRelatedTableNames.includes(tableName)) {
			return
		}
		const patient = this.data.loadedPatients.find(
			(patient) => patient.id == patientId
		)
		if (!patient) {
			return
		}
		//get mixed list from db

		const res: any = await this.apiService.get_gen_items("finResource_list", {
			filterFieldValue: patientId,
		})
		this.updatePatientFinResourceList(patientId, res.finResource_list)
		// await this.postFinresourceRefresh("patient",patient);
		this.updatePatientMisc(patientId)
	}

	async postFinresourceRefresh(person_type: string, itemToUpdate: any) {
		const res: any = await this.apiService.post("post_invoice_refresh", {
			person_type,
			item_id: itemToUpdate.id,
		})
		itemToUpdate.total_lm_debt = res.total_lm_debt
		itemToUpdate.total_lm_prices = res.total_lm_prices
		itemToUpdate.total_lm_payor_prices = res.total_lm_payor_prices
		itemToUpdate.total_until_lm_debt = res.total_until_lm_debt
		itemToUpdate.total_total_debt = res.total_total_debt
	}

	searchSiteDataItem(value: any, classes: string[]) {
		for (let className of classes) {
			//ex. "expensetypes"
			const tbl = this.getSiteDataTable(className) //ex. expensetypes collection of items
			const find = tbl.find((it) => it.id == value)
			if (find) {
				return find
			}
		}
		return null
	}

	getAssocSubUsersForFus(fus: FollowupserieSave) {
		const sub_users = this.getSiteDataTable("sub_users")
		const sub_user_groups = this.getSiteDataTable("sub_user_groups")
		const arr = []
		if (fus.assoc_sub_user_ids) {
			fus.assoc_sub_user_ids.forEach((id) => {
				const isGroup = String(id).startsWith("g")
				const idToCheck = isGroup ? id.substring(1) : id
				const arrToSearch = isGroup ? sub_user_groups : sub_users

				const find = arrToSearch.find((it) => it.id == idToCheck)
				if (find) {
					arr.push(find)
				}
			})
		}
		return arr
	}
	getFusSpreadAssocIds(fus: any) {
		const arr = fus?.assoc_sub_user_ids

		// return arr;
		const user_ids = []
		for (let id of arr) {
			if (!String(id).startsWith("g")) {
				user_ids.push(id)
				continue
			}
			const sub_user_group_id = id.substring(1)
			const group = this.getSiteDataTable("sub_user_group_members")
				.filter((it) => it.sub_user_group_id == sub_user_group_id)
				.map((it) => it.user_id)
			user_ids.push(...group)
		}
		const ret = []
		for (let id of user_ids) {
			if (!ret.includes(id)) {
				ret.push(String(id))
			}
		}
		return ret
	}

	getAssocFusIdsForUser() {
		const userId = this.getCliniqDataOrExit()?.user_id
		if (!userId) {
			return []
		}
		const fusIds = []
		this.data.followupseries.forEach((fus) => {
			const assocUserIds = this.getFusSpreadAssocIds(fus)
			if (assocUserIds.includes(String(userId))) {
				fusIds.push(fus.id)
			}
		})
		return fusIds.map((it) => String(it))
	}

	async postLoginActions() {
		// await this.timeout(5000);
		//   const res=await this.apiService.convert_meeting_requests_to_tasks();
		//   if(res?.changes){
		//     this.get_task_notifications_total();
		//   }
	}

	//LOADERS
	loadAllFollowupseries(followupseries: StoreFollowupserie[]) {
		this.removeDecArr(followupseries)
		this.data.followupseries = followupseries.filter(
			(fus) => fus.series_repeat != "series_exception"
		)
		this.data.fusExceptions = followupseries.filter(
			(fus) => fus.series_repeat == "series_exception"
		)
	}

	loadPatient(patient: StorePatient) {
		if (!patient) {
			return
		}
		let existing = this.data.loadedPatients.find(
			(dataPatient) => dataPatient.id == patient.id
		)
		if (!existing) {
			this.removeDecPatient(patient)
			this.data.loadedPatients = [...this.data.loadedPatients, { ...patient }]
			this.updatePatientPrevNext(
				patient.id,
				patient.last_followup,
				patient.next_followup
			)
		}
	}
	loadFollowup(followup: StoreFollowup) {
		if (!followup) {
			return
		}
		let existing = this.data.followups.find(
			(dataFollowup) => dataFollowup.id == followup.id
		)
		if (!existing) {
			this.removeDecItem(followup)
			this.data.followups = [...this.data.followups, { ...followup }]
		}
	}

	loadSiteData(data: any) {
		this.data.siteData = data
		this.removeDecSiteData()
	}
	loadOneSiteDataTable(tableName: string, arrRows: any[]) {
		this.removeDecArr(arrRows)
		this.data.siteData[tableName] = arrRows
		this.setSessionSiteData()
	}

	getCliniqDataOrExit() {
		const preJson = sessionStorage.getItem("cliniqData")
		if (!preJson) {
			if (this.ccForGuestCal) {
				return this.ccForGuestCal
			}
			return window.location.assign("/login") //redirect to login page
		}
		const ret = JSON.parse(preJson)
		if (!ret) {
			return window.location.assign("/login") //redirect to login page
		}
		return ret
	}

	//1. load followupserie[]:ser_id (no patients) week or month or day
	//2. load day fws[] - first with patientn
	//3. load patient

	//GETTERS

	getFusExceptions() {
		return [...this.data.fusExceptions.map((it) => ({ ...it }))]
	}
	getFollowupObjectForSeries(
		person_id: number,
		series_id: number,
		date: string,
		fieldname: PatientOrUserId
	) {
		const today = moment.utc().endOf("day")
		if (moment.utc(date).isAfter(today)) {
			return null
		}
		//is in store?
		const followup = this.data.followups.find(
			(fp) =>
				fp[fieldname] == person_id &&
				fp.series_id == series_id &&
				fp.date == date
		)

		return followup
	}

	verifyConfigCliniqIsIn() {
		return this.apiService.get_session_conf_data().pipe(
			map((res: any) => {
				this.apiService.isUserLoggedIn = true
				this.apiService.restartLogoutTimeout(res.time_to_logout)
				const lang = res?.sub_user_lang || res.lang
				if (lang != this.lang.langCode) {
					this.lang.setLang(lang)
				}
				sessionStorage.setItem("cliniqData", JSON.stringify(res))
				this.permsService.load(res?.perms, res?.owner_has_users)

				this.lang.convertLangTerminologyPreferences(res?.terminology_prefrences)

				this.initFusRefreshCheck()
				if (this.permsService.owner_has_users) {
				}

				return true
			}),
			catchError((e) => {
				// this.router.navigateByUrl("/login");
				this.apiService.isUserLoggedIn = false
				this.errorService.responseErr(e)
				return of(false)
			})
		)
	}
	activateVerifyConfigCliniqIsIn() {
		return new Promise((resolve) => {
			this.verifyConfigCliniqIsIn().subscribe((res) => {
				resolve(true)
			})
		})
	}

	isFusAReminder(fus: any) {
		return fus.followuptype_id == 4
	}

	async getFollowupForSeries(
		person_id: number,
		series_id: number,
		date: string,
		fieldname: PatientOrUserId
	) {
		const today = moment.utc().endOf("day")
		if (moment.utc(date).isAfter(today)) {
			return
		}
		//is in store?
		const followup = this.data.followups.find(
			(fp) =>
				fp[fieldname] == person_id &&
				fp.series_id == series_id &&
				fp.date == date
		)

		if (followup) {
			return followup
		}

		const fus = this.data.followupseries.find((it) => it.id == series_id)
		// if(this.isFusAReminder(fus)){ return null;}

		//get from api
		const res: any = await this.apiService.post("get_followup_for_series", {
			person_id,
			series_id,
			date,
			fieldname,
		})
		if (!res) {
			return null
		}

		this.loadFollowup(res)
		if (res?.is_created && fieldname == "patient_id") {
			this.detectMixedListChangeNeeded("followups", person_id)
		}
		this.removeDecItem(res)
		return res
	}

	async getFollowupsForPeriod(
		dateFrom: string,
		dateTo: string,
		type: "timeGridDay" | "timeGridWeek" | "dayGridMonth"
	) {
		//date to NOT INCVLUSIVE
		//makes sure that cached dates are not "recalled"
		if (this.cachedPeriods[type][dateFrom] !== undefined) {
			return
		}

		const res: any = await this.apiService.post("get_followup_for_period", {
			dateFrom,
			dateTo,
		})

		if (res.followups) {
			res.followups.forEach((fp) => {
				this.loadFollowup(fp)
			})
		}

		this.cachedPeriods[type][dateFrom] = 1
		if (type != "timeGridDay") {
			const from = moment.utc(dateFrom)
			const diff = moment.utc(dateTo).diff(from, "days")
			for (let i = 0; i < diff; i++) {
				this.cachedPeriods["timeGridDay"][
					from.format(ConfigDefinitions.momentDateFormat)
				] = 1
				from.add(1, "day")
			}
		}
	}

	async calendarLoad(doGetAll: boolean = false) {
		//
		if (doGetAll && !this.fusesLoadedFull) {
			return await this.getAllFus(true, true)
		}
		return await this.getAllFus()
	}
	getAllFusLoad(fuses: any[], meeting_requests: any[] = []) {
		this.fusesLoaded = true
		this.loadAllFollowupseries(
			fuses.filter((fus) => fus.series_limit_date != "0000-00-00")
		)
		this.meeting_requests = meeting_requests || []
	}

	async getAllFus(forceRefresh: boolean = false, doGetAll: boolean = false) {
		//followupseries:[],fusExceptions:[]
		// await this.verifyFuzzyIsIn(false);
		if (!forceRefresh && this.fusesLoaded) {
			return {
				followupseries: [...this.data.followupseries.map((it) => ({ ...it }))],
				fusExceptions: [...this.data.fusExceptions.map((it) => ({ ...it }))],
				meeting_requests: this.removeDecArr(this.meeting_requests),
			}
		}
		const res: any = await this.apiService.post("get_all_fus", {
			get_all: doGetAll,
		})
		this.getAllFusLoad(res.fuses, res?.meeting_requests)
		if (doGetAll) {
			this.fusesLoadedFull = true
		}
		return this.getAllFus()
	}
	async renewFusesAndCalendar() {
		await this.getAllFus(true)
		this.data.followups = []
		this.cachedPeriods = { dayGridMonth: {}, timeGridWeek: {}, timeGridDay: {} }
		this.calendarChangesSubj.next(true)
	}

	initFusRefreshCheck() {
		clearInterval(this.lastFusChangeInter)
		this.runFusRefreshCheck()
		this.lastFusChangeInter = setInterval(
			this.runFusRefreshCheck.bind(this),
			50 * 1000
		)
	}

	async runFusRefreshCheck() {
		const res: any = await this.apiService.post(
			"get_last_fus_update",
			this,
			"lastUpdateTime"
		)
		this.lastUpdateTime = res.lastUpdateTime
		if (res.changedFuses?.length) {
			res.changedFuses.forEach(async (fus) => {
				if (fus.status == "deleted") {
					//if status=="deleted" - go find it in store and remove it
					this.data.followupseries = this.data.followupseries.filter(
						(f) => f.id != fus.id
					)
				} else {
					this.removeDecItem(fus)

					const arrayName =
						fus.series_repeat == "series_exception"
							? "fusExceptions"
							: "followupseries"
					this.data[arrayName] = [
						...this.data[arrayName].filter((f) => f.id != fus.id),
						fus,
					]
				}

				if (fus?.patient_id) {
					const patient = this.searchSiteDataItem(fus.patient_id, ["patients"])
					if (!patient) {
						//if patient_id not in siteData -> go refresh patients for siteData and refresh calendar
						const patients = await this.apiService.post(
							"get_fuzzy_patients_with_related_tables"
						)
						if (patients && patients.length) {
							this.loadOneSiteDataTable("patients", patients)
						}
					}
				}
				//test case for new patient and for already existing

				//refresh the calendar IF IN CALENDAR URL
				this.calendarChangesSubj.next(true)
			})

			// this.timeToRefreshMessage = true;
		}
		if (res.newPatients?.length) {
			this.removeDecArr(res.newPatients)
			res.newPatients.forEach((patient) => {
				this.addGenItemRow("patients", patient)
			})
		}
		if (res?.updatedPatientIds?.length) {
			// this.data.loadedPatients=this.data.loadedPatients.filter(it=>!res.updatedPatientIds.includes(it.id));
			res.updatedPatientIds.forEach((patient_id) => {
				if (this.data.loadedPatients.find((it) => it.id == patient_id)) {
					this.data.loadedPatients = this.data.loadedPatients.filter(
						(it) => it.id != patient_id
					)
					this.getPatient(patient_id)
				}
			})

			this.refreshSiteDataPatientsByIds(res.updatedPatientIds)
			this.setSessionSiteData()
		}
		this.lockedPatientsSubject.next(res.curLockedPatientIds)

		this.processUnreadTasks(res.unreadTasks)
		// this.changedTasksSubj.next(res);
	}
	async refreshSiteDataPatientsByIds(ids: number[]) {
		const newLines = await this.apiService.post("get_patients_for_site_data", {
			ids,
		})
		this.data.siteData.patients = this.data.siteData.patients.filter(
			(it) => !ids.includes(it.id)
		)
		this.data.siteData.patients = [
			...this.data.siteData.patients,
			...this.removeDecArr(newLines.patients),
		]

		this.setSessionSiteData()
	}

	async get_task_notifications_total() {
		const res: any = await this.apiService.post("get_task_notifications_total")
		this.processUnreadTasks(res.total)
	}
	processUnreadTasks(num: number) {
		this.unreadTasks = num
	}

	findClosestOrderNum(arr: any[], numToFind: number, toLowerOrderNum: boolean) {
		const r = arr.filter((it) => {
			if (toLowerOrderNum && it.order_num < numToFind) {
				return true
			}
			if (!toLowerOrderNum && it.order_num > numToFind) {
				return true
			}
			return false
		})
		if (!r.length) {
			return null
		}
		const posToGet = toLowerOrderNum ? r.length - 1 : 0
		return r[posToGet]
	}

	async get_gen_items(tableName: string, sendObj: any = null) {
		if (tableName !== "medicpatients" && sendObj?.filterField == "patient_id") {
			let rows = this.getCollectionInPatient(
				tableName,
				sendObj.filterFieldValue
			)
			if (rows) {
				if (sendObj?.filterFieldApiObject) {
					Object.keys(sendObj.filterFieldApiObject).forEach((key) => {
						const val = sendObj.filterFieldApiObject[key]
						rows = rows.filter((row) => row[key] == val)
					})
				}
				return { [tableName]: [...rows.map((it) => ({ ...it }))] }
			}
		}

		if (
			(!sendObj || !Object.keys(sendObj).length) &&
			this.data.siteData &&
			this.data.siteData[tableName]
		) {
			return { [tableName]: this.getSiteDataTable(tableName) }
		}
		return this.apiService.get_gen_items(tableName, sendObj)
	}
	getSiteDataTable(tableName: string) {
		if (!this.data.siteData) {
			this.loadLocalSiteData()
		}
		return [...this.data.siteData[tableName].map((it) => ({ ...it }))]
	}

	async refreshPatientPrevNext(patient_id: number) {
		//get the prev and next followup to be displayed in home page side menu
		const res: any = await this.apiService.post("get_prev_next_fus", {
			patient_id,
		})
		this.updatePatientPrevNext(patient_id, res.prev, res.next)
	}
	refreshAllPatientsPrevNext() {
		this.data.loadedPatients.forEach((p) => this.refreshPatientPrevNext(p.id))
	}

	async getPatient(id: number) {
		let patient = this.data.loadedPatients.find(
			(dataPatient) => dataPatient.id == id
		)

		if (patient) {
			return patient
		}
		const res: any = await this.apiService.post("get_patient_data", {
			patient_id: id,
		})
		if (!res) {
			return null
		}
		this.loadPatient(res)
		patient = this.data.loadedPatients.find((p) => p.id == res.id)
		return patient
	}
	public getFusById(fusId: number) {
		return this.data.followupseries.find((it) => it.id == fusId)
	}

	//SETTERS

	updatePatientPartial(obj: any) {
		const patientIndex = this.data.loadedPatients.findIndex(
			(patient) => patient.id == obj.id
		)
		if (patientIndex != -1) {
			this.data.loadedPatients[patientIndex] = {
				...this.data.loadedPatients[patientIndex],
				...obj,
			}
		}
		//if patient in siteData
		if (this.data.siteData && this.data.siteData.patients) {
			const curIndex = this.data.siteData.patients.findIndex(
				(it) => it.id == obj.id
			)
			if (curIndex != -1) {
				this.data.siteData.patients[curIndex] = {
					...this.data.siteData.patients[curIndex],
					...obj,
				}
			}
			this.setSessionSiteData()
		}
	}
	updatePatientPostFollowup(patient_id: number, result: any) {
		if (patient_id) {
			const obj: any = { id: patient_id }

			this.totalFields.forEach((f) => (obj[f] = result[f]))
			this.updatePatientPartial(obj)
			this.updatePatientMisc(patient_id)
		}
	}

	pushPatientCollection(
		patient_id: number,
		tableName: string,
		collection: any[]
	) {
		this.patientCollectionsSub.next({ patient_id, name: tableName, collection })
	}

	callFinresourceActionSub() {
		this.finresouecrActionSubj.next(true)
	}

	addGenItemRow(tableName: string, row: any, filterData: any = null) {
		if (filterData && filterData.patient_id) {
			const patient = this.data.loadedPatients.find(
				(patient) => patient.id == filterData.patient_id
			)

			this.detectFinResourceListChangeNeeded(tableName, patient?.id)

			if (patient && patient[tableName]) {
				const curIndex = patient[tableName].findIndex((it) => it.id == row.id)
				if (curIndex != -1) {
					patient[tableName][curIndex] = { ...row }
				} else {
					patient[tableName] = [{ ...row }, ...patient[tableName]]
				}
				this.detectMixedListChangeNeeded(tableName, patient.id)
				this.patientCollectionsSub.next({
					patient_id: filterData.patient_id,
					name: tableName,
					collection: patient[tableName],
				})

				return
			}
		}

		if (["groups", "inquirers"].includes(tableName)) {
			tableName = "patients"
		}

		if (this.data.siteData && this.data.siteData[tableName]) {
			const curIndex = this.data.siteData[tableName].findIndex(
				(it) => it.id == row.id
			)
			if (curIndex != -1) {
				this.data.siteData[tableName][curIndex] = { ...row }
			} else {
				this.data.siteData[tableName] = [
					...this.data.siteData[tableName],
					{ ...row },
				]
			}
			this.setSessionSiteData()
			return
		}
	}

	siteDataUpdateRow(tableName: string, row: any) {
		if (this.data.siteData && this.data.siteData[tableName]) {
			const curIndex = this.data.siteData[tableName].findIndex(
				(it) => it.id == row.id
			)
			if (curIndex != -1) {
				this.data.siteData[tableName][curIndex] = {
					...this.data.siteData[tableName][curIndex],
					...row,
				}
				this.setSessionSiteData()
			}
		}
	}

	constDeletePatientAssoc(patient_id: number, user_id: number) {
		const patient = this.data.loadedPatients.find(
			(patient) => patient.id == patient_id
		)
		if (!patient) {
			return
		}
		patient.assoc = patient.assoc.filter((it) => it != user_id)
	}

	updGenItemRowField(
		tableName: string,
		id: number,
		fieldName: string,
		value: any,
		filterData: any = null
	) {
		if (filterData && filterData.patient_id) {
			const patient = this.data.loadedPatients.find(
				(patient) => patient.id == filterData.patient_id
			)
			this.detectFinResourceListChangeNeeded(tableName, patient?.id)

			if (patient && patient[tableName]) {
				let index = patient[tableName].findIndex((it) => it.id == id)
				if (index != -1) {
					patient[tableName][index] = {
						...patient[tableName][index],
						[fieldName]: value,
					}
					this.detectMixedListChangeNeeded(tableName, patient.id)
					return
				}
			}
		}

		if (["groups", "inquirers"].includes(tableName)) {
			tableName = "patients"
		}
		if (this.data.siteData && this.data.siteData[tableName]) {
			let index = this.data.siteData[tableName].findIndex((it) => it.id == id)
			if (index != -1) {
				this.data.siteData[tableName][index] = {
					...this.data.siteData[tableName][index],
					[fieldName]: value,
				}
				this.setSessionSiteData()
				return
			}
		}
	}
	delGenItemRow(tableName: string, id: number, filterData: any = null) {
		if (filterData && filterData.patient_id) {
			const patient = this.data.loadedPatients.find(
				(patient) => patient.id == filterData.patient_id
			)
			this.detectFinResourceListChangeNeeded(tableName, patient?.id)

			if (patient && patient[tableName]) {
				let index = patient[tableName].findIndex((it) => it.id == id)
				if (index != -1) {
					patient[tableName] = patient[tableName].filter((it) => it.id != id)
					this.detectMixedListChangeNeeded(tableName, patient.id)
					return
				}
			}
		}

		if (["groups", "inquirers"].includes(tableName)) {
			tableName = "patients"
		}
		if (this.data.siteData && this.data.siteData[tableName]) {
			let index = this.data.siteData[tableName].findIndex((it) => it.id == id)
			if (index != -1) {
				this.data.siteData[tableName] = this.data.siteData[tableName].filter(
					(it) => it.id != id
				)
				this.setSessionSiteData()
				return
			}
		}
	}
	deleteTest(test_id: number) {
		const tests = this.data.siteData["tests"].filter(
			(it) => it.test_id == test_id
		)
		tests.forEach((t) => this.deleteTest(t.id))

		const questions = this.data.siteData["testquestions"].filter(
			(it) => it.test_id == test_id
		)
		questions.forEach((q) => this.delGenItemRow("testquestions", q.id))

		this.delGenItemRow("tests", test_id)
	}
	updatePatientTags(tags: any[], item_type: string, item_id: number) {
		const object_to_tags = this.getSiteDataTable("object_to_tags").filter(
			(it) => it.item_id != item_id || it.item_type != item_type
		)
		this.data.siteData["object_to_tags"] = [...object_to_tags, ...tags]
		this.setSessionSiteData()
	}

	updatePatient(patient: StorePatient) {
		const index = this.data.loadedPatients.findIndex(
			(it) => it.id == patient.id
		)
		if (index != -1) {
			this.data.loadedPatients[index] = {
				...this.data.loadedPatients[index],
				...patient,
			}
		}
		//update in siteData
		const patientWithNoArrays = {}
		Object.keys(patient).forEach((key) => {
			const value = patient[key]
			if (!Array.isArray(value) && !["patienttype", "payor"].includes(key)) {
				patientWithNoArrays[key] = value
			}
		})
		this.siteDataUpdateRow("patients", patientWithNoArrays)
	}
	updateFollowup(followup: StoreFollowup) {
		const index = this.data.followups.findIndex((it) => it.id == followup.id)
		if (index != -1) {
			this.data.followups[index] = {
				...this.data.followups[index],
				...followup,
			}
		}
	}
	addFus(fus: FollowupserieSave | StoreFollowupserie) {
		this.removeDecItem(fus)
		;(this.data.followupseries as any) = [
			...this.data.followupseries,
			{ ...fus },
		]
		this.postFusChangeForPrevNext(fus as any)
	}
	updateFus(id: number, overrideObj: any) {
		this.removeDecItem(overrideObj)
		const index = this.data.followupseries.findIndex((fus) => fus.id == id)
		if (index != -1) {
			this.data.followupseries[index] = {
				...this.data.followupseries[index],
				...overrideObj,
			}
			this.postFusChangeForPrevNext(overrideObj)
		}
	}
	addFusExceptionArr(
		fusExceptionArr: FollowupserieSave[] | StoreFollowupserie[]
	) {
		;(this.data.fusExceptions as any) = [
			...this.data.fusExceptions,
			...fusExceptionArr,
		]
		this.postFusChangeForPrevNext(fusExceptionArr as any)
	}
	removeFus(id: number) {
		const fus = this.data.followupseries.find((fus) => fus.id == id)
		this.data.followupseries = this.data.followupseries.filter(
			(fus) => fus.id != id
		)
		this.postFusChangeForPrevNext(fus)
	}
	overrideRecentFollowups(recentFollowups: StoreFollowup[]) {
		//only override RECENT followups
		const beginning: string = moment
			.utc()
			.startOf("day")
			.subtract(3, "days")
			.format(ConfigDefinitions.momentDateFormat)
		this.removeDecArr(recentFollowups) //first day of NON-RECENT
		this.data.followups = this.data.followups
			.filter((f) => moment.utc(f.date).isBefore(beginning))
			.concat(...recentFollowups)
	}

	updatePatientPrevNext(patientId: number, prev: number, next: number) {
		const patient = this.data.loadedPatients.find((p) => p.id == patientId)
		if (patient) {
			patient.last_followup = this.getDaysDiff(prev)
			patient.next_followup = this.getDaysDiff(next)
			this.patientPrevNextSub.next({
				patientId: patient.id,
				last_followup: patient.last_followup,
				next_followup: patient.next_followup,
			})
		}
	}
	updatePatientMixedList(patientId: number, mixed_list: any) {
		const patient = this.data.loadedPatients.find(
			(patient) => patient.id == patientId
		)
		if (patient) {
			patient.mixed_list = mixed_list
			this.patientCollectionsSub.next({
				patient_id: patientId,
				name: "mixed_list",
				collection: patient.mixed_list,
			})
		}
	}
	updatePatientMisc(patientId: number) {
		const patient = this.data.loadedPatients.find(
			(patient) => patient.id == patientId
		)
		if (patient) {
			this.patientMiscSub.next({ patient_id: patientId, patient })
		}
	}
	updatePatientFinResourceList(patientId: number, finResource_list: any) {
		const patient = this.data.loadedPatients.find(
			(patient) => patient.id == patientId
		)
		if (patient) {
			patient.finResource_list = finResource_list
			this.patientCollectionsSub.next({
				patient_id: patientId,
				name: "finResource_list",
				collection: patient.finResource_list,
			})
		}
	}
	updatePatientGenCollection(
		patientId: number,
		collection: any,
		collectionName: string
	) {
		const patient = this.data.loadedPatients.find(
			(patient) => patient.id == patientId
		)
		if (patient) {
			patient[collectionName] = collection
			this.patientCollectionsSub.next({
				patient_id: patientId,
				name: collectionName,
				collection,
			})
		}
	}

	sanitizeWysiwyg(str: any) {
		if (!str) {
			return ""
		}

		const allowedTags =
			"u,b,i,div,font,span,br,hr,ol,li,ul,table,thead,th,td,tr,tbody,h1,h2,h3,h4,h5,h6,p,strong,small,strike,center,title,em,i".split(
				","
			)
		const allowedProps = "color,style,class,colspan".split(",")
		const allowedStyleAttrs =
			"background-color,direction,text-align,font-size,width,line-height,color,font-weight".split(
				","
			)

		const quotes = ['"', "'"]

		let segs = str.split("<")
		segs.shift()
		for (let seg of segs) {
			let tag = ""
			let r = null
			if (seg.substring(0, 1) == "/") {
				r = seg.substring(1)

				r = r.split(">")
				tag = r[0]

				if (!allowedTags.includes(tag.toLowerCase())) {
					str = str.replaceAll("</" + tag, "</span")
				}
			} else {
				//find next space or >
				let r = seg.split(" ")
				let tag = r[0]
				r = tag.split("/>")
				tag = r[0]
				r = tag.split(">")
				tag = r[0]
				if (!allowedTags.includes(tag.toLowerCase())) {
					str = str.replaceAll("<" + tag, "<span")
				}
			}
		}

		segs = str.split("<")
		segs.shift()
		for (let seg of segs) {
			if (seg.substring(0, 1) == "/") {
				continue
			}

			let r = seg.split(" ")
			let tag = r[0]
			r = tag.split("/>")
			tag = r[0]
			r = tag.split(">")
			tag = r[0]

			let rest = seg.substring(tag.length).trim().split(">")[0].trim()
			if (!rest) {
				continue
			}

			let props = rest.split("=")
			// props.pop();
			for (let p = 0; p < props.length - 1; p++) {
				//no reason to iterate the last value
				const prop = props[p]
				const pName = prop.split(" ").pop()
				if (!allowedProps.includes(pName.toLowerCase())) {
					//get next prop value
					const nextVal = props[p + 1]

					let firstChar = nextVal.substring(0, 1)

					let end = nextVal.indexOf(" ")
					if (firstChar == '"' || firstChar == "'") {
						end = nextVal.indexOf(firstChar, 1)
					}
					const takeOut = nextVal.substring(0, end + 1)

					str = str.replaceAll(pName + "=" + takeOut, "")
				}
			}

			//figure out legal style attributes
			for (let quot of quotes) {
				const toSearch = "style=" + quot
				const styleParts = seg.split(toSearch)
				styleParts.shift()
				for (let stylePart of styleParts) {
					const st = stylePart.split(quot)[0].trim()
					const attrs = st.split(";")
					for (let attr of attrs) {
						attr = attr.trim()
						if (!attr.length) {
							continue
						}
						const attrArr = attr.split(":")
						const attrName = attrArr[0]

						if (!allowedStyleAttrs.includes(attrName.trim().toLowerCase())) {
							str = str.replaceAll(attr, "")
						}
					}
				}
			}
		}

		return str

		//             }
		//         }
		//     }
		//     return true;

		// }
	}

	async requireDefaultSignature() {
		//return promise that resolves to boolen: if no need for sig OR sig exists OR sig was created
		let returnValue = true
		if (!this.permsService.owner_has_users) {
			return true
		}
		const cc = this.getCliniqDataOrExit()

		if (cc.default_signature) {
			return true
		}
		// while (!cc.default_signature) {
		cc.default_signature = await this.modalService.openMulti("update-field", {
			type: "textarea",
			fieldName: "default_signature",
		})
		if (cc.default_signature) {
			sessionStorage.setItem("cliniqData", JSON.stringify(cc))
			this.apiService.post("update_default_signature", cc, "default_signature")
		}
		returnValue = !!cc.default_signature
		return returnValue
	}

	getPatientAndMaybeIdNumber(patient_id: number, isPatient: boolean = false) {
		if (!isPatient) {
			return ""
		}
		const patient = this.searchSiteDataItem(patient_id, ["patients"])
		if (!patient) {
			return ""
		}
		const cc = this.getCliniqDataOrExit()
		if (cc?.id_on_invoice != "yes" || !patient.id_number) {
			return ""
		}

		return " " + patient.id_number
	}

	markInObj(obj: any, fieldsArr: string[], search: string) {
		fieldsArr.forEach((f) => (obj[f + "_marked"] = obj[f] || ""))
		if (!search) {
			return obj
		}
		const rep = `<span style='background-color:yellow;' >${search}</span>`
		fieldsArr.forEach(
			(f) => (obj[f + "_marked"] = (obj[f] || "").replaceAll(search, rep))
		)
		return obj
	}
	getNonClinicalSubUserIds() {
		return this.getSiteDataTable("sub_users")
			.filter((it) => !it?.is_clinical || it.active == "no")
			.map((it) => it.id)
	}

	getInquiryStatuses() {
		return [{ id: 0, name: "" }, ...this.getSiteDataTable("inquiry_statuses")]
	}
	getInquiryStatusNamesAsObj() {
		const inquiry_statuses_names = {}
		const arr = this.getInquiryStatusNamesAsArray()
		arr.forEach((it) => (inquiry_statuses_names[it.id] = it.name))
		return inquiry_statuses_names
	}
	getInquiryStatusNamesAsArray() {
		const inquiry_statuses_names = [
			{ id: -6, name: this.lang.getVal("irrelevant") },
			{ id: -5, name: this.lang.getVal("new_inquiry_with") },
			{ id: -4, name: this.lang.getVal("new_inquiry_without") },
			{ id: -3, name: this.lang.getVal("unassigned_to_assoc") },
			{ id: -2, name: this.lang.getVal("assigned_to_assoc") },
			{ id: -1, name: this.lang.getVal("meeting_was_set") },
		]
		const arr = this.getInquiryStatuses()
		return [...inquiry_statuses_names, ...arr]
	}
	minutesToHI(minutes: number) {
		const hours = Math.floor(minutes / 60)
		minutes = minutes % 60
		return this.to2Didigts(hours) + ":" + this.to2Didigts(minutes)
	}
	to2Didigts(num: number) {
		return num < 10 ? "0" + num : num
	}

	getPreppedPatientFields() {
		const arr = this.getSiteDataTable("patientfields").filter(
			(row) => row?.show != "no"
		)
		arr.sort((a: any, b: any) => a.order - b.order)
		return arr
	}

	crGenTableUpdateField(
		genTableFieldName: string,
		incomingRowFieldName: string
	) {
		return { genTableFieldName, incomingRowFieldName }
	}

	updateGenTable(
		tableName: string,
		rows: any[],
		fieldsToUpdate: GenTableUpdateField[],
		idField: string = "id"
	) {
		const obj: GenTableUpdate = { tableName, idField, rows, fieldsToUpdate }
		this.updateTableSubject.next(obj)
	}

	async getPatientWithPreppedTests(patient_id: number) {
		const patient: any = await this.getPatient(patient_id)

		const patientTestfills: PatientTestObj[] = []

		const ts = this.getSiteDataTable("tests")
		const qs = this.getSiteDataTable("testquestions")

		const testfills = patient.testfills

		testfills.forEach((testfill) => {
			//get the test that belongs to me
			const test = ts.find((t) => t.id == testfill.test_id)
			if (test) {
				//if questions exist that have this test_id - add them and their answers (that have MY testfill_id)
				const questions = qs
					.filter((q) => q.test_id == test.id)
					.map((q) => {
						let a =
							patient.testanswers.find(
								(ta) =>
									ta.testquestion_id == q.id && ta.testfill_id == testfill.id
							)?.answer ?? ""
						return { q: q.question, a, order_num: q.order_num }
					})
				questions.sort((a: any, b: any) => a.order_num - b.order_num)

				//if tests exist that have this test_id - add them
				const tests = ts
					.filter((t) => t.test_id == test.id)
					.map((t) => {
						const questions = qs
							.filter((q) => q.test_id == t.id)
							.map((q) => {
								let a =
									patient.testanswers.find(
										(ta) =>
											ta.testquestion_id == q.id &&
											ta.testfill_id == testfill.id
									)?.answer ?? ""
								return { q: q.question, a, order_num: q.order_num }
							})
						questions.sort((a: any, b: any) => a.order_num - b.order_num)
						return { ...t, questions }
					})

				patientTestfills.push({
					...test,
					testfill_id: testfill.id,
					html_text: testfill.html_text,
					date: testfill.created_at,
					questions,
					tests,
				})
			}
		})
		patientTestfills.sort((a: PatientTestObj, b: PatientTestObj) =>
			new Date(a.date) < new Date(b.date) ? 1 : -1
		)

		patient.tests = [...patientTestfills]
		this.pushPatientCollection(patient_id, "tests", patient.tests)

		return patient
	}

	async downloadFailedModal(timeoutMilli = 5000) {
		await this.timeout(timeoutMilli)
		this.modalService.openToast(this.lang.getVal("download_failed"))
		this.downloadSubject.next(false)
	}

	async deactivate_meeting_request(
		id: number,
		fus_date: string = null,
		fus_time: string = null,
		fus_patient_id: number = 0,
		is_accept: boolean = false
	) {
		const res: any = await this.apiService.post("deactivate_meeting_request", {
			id,
			fus_date,
			fus_time,
			fus_patient_id,
			is_accept,
		})
		this.meeting_requests = this.meeting_requests.filter((it) => it.id != id)
		return res
	}

	async patientSendInvite(patient_id: number, test_ids: number[] = null) {
		const arr: any = await this.modalService.openMulti("gen-table-display", {
			tableName: "contactways",
			filterField: "patient_id",
			fieldNameInItemToDisplay: "contactway",
			modalWidth: "modal-800",
			chooseOnlyOne: true,
			filterFieldOrs: { contactway_identifier: ["email", "phone"] },
			filterFieldValue: patient_id,
		})
		if (!arr?.length) {
			return
		}
		const contactway = arr[0]
		const { isWhatsapp } = contactway

		const isTests = test_ids !== null

		const res = await this.apiService.post(
			isTests ? "patient_test_invite" : "invite_patient_fill_details",
			{ patient_id, test_ids, contactway_id: contactway.id, is_wa: isWhatsapp }
		)
		if (isWhatsapp) {
			return this.patientSendInviteWhatsapp(
				res,
				contactway,
				patient_id,
				test_ids
			)
		}
		this.modalService.openToast(
			this.lang.getVal(isTests ? "test_sent" : "send_invite_to_fill")
		)
	}
	async patientSendInviteWhatsapp(
		res: any,
		contactway: any,
		patient_id: number,
		test_ids: number[] = null
	) {
		const isTests = test_ids !== null

		const waphone = contactway.contactway.substring(1)
		const patient = this.searchSiteDataItem(patient_id, ["patients"])

		const isMultipleTests = isTests && res.urls.length > 1
		const fillText = this.lang.getVal(
			isMultipleTests ? "please_fill_in_the_links" : "please_fill_in_the_link"
		)
		const urlText = isTests ? res.urls.map((obj) => obj.url).join("%0A") : res
		const message_text =
			this.lang.getVal("hello") +
			" " +
			patient.name +
			"   %0A" +
			fillText +
			"   %0A%0A   " +
			urlText

		const cc = this.getCliniqDataOrExit()

		const pre = cc?.wa_app_installed == "yes" ? "api" : "web"
		const linkStart = this.isMobile
			? "https://wa.me/972" + waphone + "?"
			: "https://" + pre + ".whatsapp.com/send/?phone=972" + waphone + "&"
		const url = linkStart + "text=" + message_text + "&app_absent=1"
		window.open(url, "_blank")
		this.modalService.openToast(this.lang.getVal("test_sent"))
	}

	async openPreviousDebtModal(patient_id: number) {
		const cc = this.getCliniqDataOrExit()
		const prevDebt = await this.modalService.openMulti("prompt-input-field", {
			headerTitle: "former_debt",
			bodyTitle: "former_debt_text",
			num: 0,
		})
		if (!prevDebt) {
			return false
		}
		const today = moment.utc().format(ConfigDefinitions.momentDateFormat)
		const fus: any = {
			// assoc_sub_user_ids: this.permsService.owner_has_users?[]:[], //todo fill auth-user id
			assoc_sub_user_ids: [cc.user_id], //todo fill auth-user id
			change_series: "only_one",
			charged: "yes",
			contact_id: 0,
			current_original_date: "",
			current_original_date_to: "",
			followupserie_date: today,
			followupserie_date_to: today,
			followupserie_du_message: "",
			followupserie_du_remarks: "",
			followupserie_id: 0,
			followupserie_series_id: 0,
			followupserie_time: "07:00",
			followupserie_time_to: "07:20:00",
			followupserie_until_date: "",
			followuptype_id: 1,
			id: 0,
			invoice_id_patient: null,
			is_all_day: "no",
			is_assoc: "yes", // this is the default
			is_create_overlap_exceptions: "no",
			is_group: "no",
			is_new_patient_or_contact: "no",
			length: 20,
			location_id: this.permsService?.owner_has_users
				? this.getSiteDataTable("locations")[0]?.id || 0
				: 0,
			meeting_on_holiday: "no",
			meeting_request_id: 0,
			meeting_title: "",
			meeting_with: "patient",
			meetingtype_id: -1,
			mode: "add",
			name: "",
			new_phone_input: "",
			notes: "",
			notification_default: "sms",
			notifiy: "no",
			patient_id,
			patient_name: "",
			payor_id: 0,
			price: prevDebt,
			repeat_days: 0,
			ser_id: 0,
			series_limit_date: "",
			series_repeat: "one_time",
			start_day: moment.utc().day(),
			takes_place: "no",
			tarif_id: this.permsService?.owner_has_users
				? this.getSiteDataTable("tarifs")[0]?.id || 0
				: 0,
			time: "",
			time_to: "",
			user_id: 0,
			vacation_all_users: "no",
		}

		const res: any = await this.apiService.post("prepare_for_save", fus)
		if (!res) {
			return false
		}

		if (fus.patient_id) {
			this.detectMixedListChangeNeeded("followups", fus.patient_id)
		}

		if (res.add) {
			this.addFus(res.add)
		}

		if (res.followups) {
			//get date (today -2)
			//in store - override all follouwps from date
			this.overrideRecentFollowups(res.followups)
		}
		return true
	}

	numberFormat(n: any) {
		return this.numberFormatObj.format(Number(n))
	}

	async nextProjectedIdPrompt(doctype: string) {
		//if user has not defined "invoice_num" - make them define it.
		const cliniqData = this.getCliniqDataOrExit()

		//generfiy this for proforma as well
		const fieldName = `${doctype}_num`

		const newId = await this.modalService.openMulti("prompt-input-field", {
			doctype,
			num: cliniqData[fieldName],
		})
		let num = Number(newId)
		if (num <= 0 || Number.isNaN(num)) {
			this.modalService.openToast(this.lang.getVal("first_invoice_num_error"))
			return false
		}

		cliniqData[fieldName] = num
		sessionStorage.setItem("cliniqData", JSON.stringify(cliniqData))
		//update the server
		const obj: any = { [fieldName]: newId }
		this.apiService.post("update_configcliniq_data", {
			...obj,
			update: "taxes",
		})
		return true
	}

	downloadToastNum = 0
	async downloadToastFunc(func: Function) {
		this.downloadSubject.next(true)
		this.downloadToastNum++
		const num = this.downloadToastNum
		await func()
		if (num == this.downloadToastNum) {
			this.downloadSubject.next(false)
		}
	}

	async genSaveFollowup(followup: any) {
		const res: any = await this.apiService.post("save_followup_ajax", followup)
		if (res) {
			if (res.deleted) {
				return
			}
			followup.updated_at = res.updated_at
			followup.sub_user_tarif = res.sub_user_tarif
			//price,price_payor,patient_price
			followup.price = res.price
			followup.price_payor = res.price_payor
			if (followup.patient_id) {
				this.detectMixedListChangeNeeded("followups", followup.patient_id)
			}
			this.updateFollowup(followup as StoreFollowup)
			this.updatePatientPostFollowup(followup.patient_id, res)
			//this.modalService.openToast(this.lang.getVal("updated_successfully"));
			return true
		} else {
			const message = this.lang.getVal("save_failed")
			this.modalService.openToast(message)
			return false
		}
	}

	async getNextId(doctype: string, objectRow: any = null) {
		const res: any = await this.apiService.post("get_next_projected_id", {
			tableName: doctype + "s",
		})
		if (doctype == "prescription") {
			return res?.nextId || 1
		}
		let id = res.nextId

		//no rows in table (returns 0) OR actual next row number
		if (res?.isNew) {
			let success = await this.nextProjectedIdPrompt(doctype)
			if (!success) {
				return false
			}
			let cliniqData = this.getCliniqDataOrExit()
			const fieldName = `${doctype}_num`
			id = cliniqData[fieldName]
		}

		if (objectRow) {
			objectRow.formerId = objectRow.id
			objectRow.id = id
		}
		return id
	}

	async getHeadtureRow({
		doctype,
		requireHeaderTextFormat,
		isTranslation,
		isDocument,
	}: {
		doctype?: string
		requireHeaderTextFormat?: boolean
		isTranslation?: boolean
		isDocument?: boolean
	} = {}) {
		// let shortDocType = doctypeToShort[doctype];
		let shortDocType = "i"
		if (doctype == "prescription") {
			shortDocType = "p"
		}
		//api get invoice
		let sendObj: any = {
			filterField: "header_default",
			filterFieldValue: shortDocType,
			filterFieldApiObject: {
				lang: isTranslation ? "en" : "iw",
				user_id: null,
			},
		}
		if (isDocument) {
			sendObj = { filterField: "header_default", filterFieldValue: "l" }
			if (this.permsService?.owner_has_users) {
				sendObj.filterFieldApiObject = {
					lang: this.lang.langCode,
					user_id: null,
				}
			}
		}
		let headtureRow = null

		const res: any = await this.apiService.get_gen_items("headtures", sendObj)
		if (!res.headtures.length) {
			const translationObj = isTranslation
				? { newRowInjectedFields: [{ name: "lang", value: "en" }] }
				: {}

			let newRow = await this.modalService.openMulti("gen-table-add-item", {
				tableName: "headtures",
				restrictedAddMode: true,
				...sendObj,
				...translationObj,
			})
			if (!newRow) {
				if (isDocument) {
					let cliniqData = this.getCliniqDataOrExit()
					let data = `${cliniqData.name}`
					newRow = { header_text: data, signature_text: data }
				} else {
					return null
				}
			}
			headtureRow = newRow
		} else {
			headtureRow = res.headtures[0]
			if (requireHeaderTextFormat && headtureRow.header_text === null) {
				headtureRow = await this.modalService.openMulti("gen-table-display", {
					tableName: "headtures",
					...sendObj,
					updateRow: true,
				})
				if (!headtureRow?.header_text) {
					return null
				}
			}
		}
		return headtureRow
	}
	getPriodFromTo(period: string) {
		let from = new Date()
		from.setDate(1)
		let to = new Date()
		to.setDate(1)

		switch (period) {
			case "this_year":
				from.setMonth(0)
				to.setFullYear(to.getFullYear() + 1)
				to.setMonth(0)
				to.setDate(0)
				break
			case "last_year":
				from.setFullYear(from.getFullYear() - 1)
				from.setMonth(0)
				to.setFullYear(to.getFullYear())
				to.setMonth(0)
				to.setDate(0)
				break
			case "this_month":
				to.setMonth(to.getMonth() + 1)
				to.setDate(0)
				break
			case "month_before":
				from.setMonth(from.getMonth() - 1)
				to.setMonth(to.getMonth())
				to.setDate(0)
				break
			case "two_months_before":
				from.setMonth(from.getMonth() - 2)
				to.setMonth(to.getMonth())
				to.setDate(0)
				break
			case "last_30_days":
				to = new Date()
				from = new Date()
				from.setDate(from.getDate() - 30)
				break
			case "last_90_days":
				to = new Date()
				from = new Date()
				from.setDate(from.getDate() - 90)
				break
			case "last_365_days":
				to = new Date()
				from = new Date()
				from.setDate(from.getDate() - 365)
				break
		}
		const fromString = moment
			.utc(from)
			.format(ConfigDefinitions.momentDateFormat)
		const toString = moment.utc(to).format(ConfigDefinitions.momentDateFormat)
		return [fromString, toString]
	}
	async openGoogle2faModal() {
		// const comp:any=await import("@app/google2fa-qr-code/google2fa-qr-code.component");

		this.modalService.openMulti("google2fa-qr-code")
	}
	processHtmlPreDl(html: string) {
		//normalize rendered HTML by removing component class and comment strings
		// html=html.replace(/<button.+<\/button>/g,"").replace(/\n/g,"");
		//_ngcontent-rin-c97=""
		html = html.replace(/_ngcontent-{1}[a-z-A-Z0-9]+=""/g, "") //remove component class attributes
		// html = html.replace(/ng-reflect[a-zA-Z0-9-]*="[a-zA-Z0-9 -]*"/g, "");	//remove component class attributes
		//<!--bindings={"ng-reflect-ng-for-of": "[object Object]"}-->
		html = html.replace(/<!--[^<]+-->/g, "") //replace comments (including non binded components)
		return html
	}
	getNameVars(tableName: string, varName: "allName" | "collectionName") {
		switch (varName) {
			case "allName":
				switch (tableName) {
					case "mixed_list":
						return "followups"
					case "medicpatients2":
						return "medicpatients"
					case "solo_time_allocations":
						return "time_allocations"
					// case "groups":
					// 	return "patients";
					// case "notifications2":
					// 	return "notifications";
				}
				break
			case "collectionName":
				switch (
					tableName
					// case "notifications2":
					// 	return "notifications";
				) {
				}
				break
		}
		return tableName
	}
	getDateExpandName(fieldName: string) {
		//get the alternate date field name
		return fieldName + "_dateObj563"
	}

	//dates:
	timeNoSeconds(str: string) {
		const r = str.split(":")
		if (r.length > 2) {
			return r[0] + ":" + r[1]
		}
		return str
	}
	flipDateOrder(str: string) {
		if (!str) {
			return str
		}
		let arr = str.split("-")
		return `${arr[2]}-${arr[1]}-${arr[0]}`
	}
	formatDate(d: Date, isFlipped: boolean = false) {
		let m: any = d.getMonth() + 1
		let dat: any = d.getDate()
		m = m < 10 ? "0" + m : m
		dat = dat < 10 ? "0" + dat : dat
		let retValue = `${dat}-${m}-${d.getFullYear()}`
		return isFlipped ? this.flipDateOrder(retValue) : retValue
	}
	getDateStr(addMonth: boolean = false, isMonthBefore: boolean = false) {
		let d = new Date()
		d.setDate(1)
		if (isMonthBefore) {
			d.setMonth(d.getMonth() - 1)
		}
		if (addMonth) {
			d.setMonth(d.getMonth() + 1)
			d.setDate(0)
		}
		return this.flipDateOrder(this.formatDate(d))
	}
	getToday() {
		let d = new Date()
		return this.flipDateOrder(this.formatDate(d))
	}
	dateFieldsInfluences(
		actualObj: any,
		influencerField: string,
		influencedField: string,
		influencedView: any
	) {
		if (actualObj[influencerField]) {
			const from = new Date(actualObj[influencerField])
			if (
				!actualObj[influencedField] ||
				from.getTime() > new Date(actualObj[influencedField])?.getTime()
			) {
				actualObj[influencedField] = actualObj[influencerField]
				if (influencedView) {
					influencedView.refreshWithModel()
				}
			}
		}
	}
	isDateRecentPast(date: string | Date) {
		//is given date, returns: the date is LESS THAN (currently) 3 days ago
		return moment.utc().diff(moment.utc(date), "day") <= 3
	}
	timeToMoment(time: string) {
		const today = moment.utc()
		return moment.utc(
			today.format(ConfigDefinitions.momentDateFormat) + " " + time + "Z"
		)
	}
	getMomentDisplay(date: any) {
		return moment.utc(date).format(ConfigDefinitions.momentDateDisplay)
	}

	timeout(t: number = 0) {
		return new Promise((resolve) => setTimeout(resolve, t))
	}

	createUrlForImage(imageObj: any) {
		imageObj.url = environment.serverUrl + "/followup-image/" + imageObj.id
		return imageObj
	}
	async openEditFollowup(followup: any) {
		if (followup.patient_id) {
			const patient = await this.getPatient(followup.patient_id)
			return await this.modalService.openMulti("patient", {
				followup,
				patient,
				isHomeMeetingOnly: true,
			})
		}
		const user = this.searchSiteDataItem(followup.user_id, ["sub_users"])
		return await this.modalService.openMulti("home-meeting", {
			followup,
			user,
			isModal: true,
		})
	}
	async openEditPatient(patient: any) {
		await this.modalService.openMulti("patient", { patient, isHome: false })
	}
	getPhoneContactway(patient: any, isBy: string = null) {
		let phone = null
		if (isBy == "sms" || isBy == "whatsapp") {
			const contactways = patient.contactways
			phone = contactways.find(
				(c) =>
					c.contactway_identifier == "phone" &&
					c.main == "yes" &&
					(c.contactway.indexOf("05") === 0 ||
						c.contactway.indexOf("9725") === 0)
			)
			if (!phone) {
				phone = contactways.find(
					(c) =>
						c.contactway_identifier == "phone" &&
						(c.contactway.indexOf("05") === 0 ||
							c.contactway.indexOf("9725") === 0)
				)
				if (!phone?.contactway) {
					return null
				}
			}
		}
		return phone
	}
	getPatientEmailOrPhone(patient: any, isPhone: boolean) {
		const field = isPhone ? "phone" : "email"
		const contactways = patient.contactways
		let found = contactways.find(
			(c) => c.contactway_identifier == field && c.main == "yes"
		)
		if (found) {
			return found
		}
		return contactways.find((c) => c.contactway_identifier == field)
	}
	async sendProforma(patient: any, isBy: string = null) {
		let phone = this.getPhoneContactway(patient, isBy)
		if (!phone && (isBy == "whatsapp" || isBy == "phone")) {
			this.modalService.openToast(this.lang.getVal("no_valid_phone"))
			return
		}

		const res = await this.apiService.post("pre_send_proforma_text", {
			patient_id: patient.id,
			isBy: isBy,
		})

		const message_text = res.message_text

		if (!message_text) {
			this.modalService.openToast(this.lang.getVal("no_followups_or_debt"))
			return
		}

		if (isBy == "whatsapp") {
			const waphone = phone?.contactway.substring(1)

			const cc = this.getCliniqDataOrExit()
			this.isMobile = window.innerWidth < 992
			this.whatsappUrl =
				"https://api.whatsapp.com/send/?phone=972" +
				waphone +
				"&text=" +
				message_text +
				"&app_absent=1"
			// if(this.isMobile ){  //|| cc?.wa_app_installed=="yes"
			//   this.whatsappUrl ="https://wa.me/972"+waphone+"?text="+message_text
			//
			// }else if(cc?.wa_app_installed=="yes"){
			//   this.whatsappUrl  ="https://api.whatsapp.com/send/?phone=972"+waphone+"&text="+message_text+"&app_absent=1";
			// }else{
			//   this.whatsappUrl  ="https://web.whatsapp.com/send/?phone=972"+waphone+"&text="+message_text+"&app_absent=1";
			//
			// }
			window.open(this.whatsappUrl, "_blank")

			return
		}

		const confirmSend = await this.modalService.openMulti("confirm", {
			actionLang: this.lang.getVal("confirm_sending"),
			freeText: message_text.replace(/\r/g, "<br />"),
		})
		if (!confirmSend) {
			return
		}

		let emailsList = null

		if (isBy == "email") {
			emailsList = await this.modalService.openMulti("gen-table-display", {
				tableName: "contactways",
				filterField: "patient_id",
				filterFieldValue: patient.id,
				fieldNameInItemToDisplay: "contactway",
				isCheckItems: true,
				filterFieldApiObject: { contactway_identifier: "email" },
			})
			if (!emailsList) {
				return
			}
		}
		await this.apiService.post("send_proforma_text", {
			patient_id: patient.id,
			emailsList,
		})
		this.modalService.openToast(this.lang.getVal("sent_successfully"))
	}
	getExcludedIdsByTagObjs(tagObjs: any[]) {
		if (!tagObjs?.length) {
			return this.getNonClinicalSubUserIds()
		}
		const excludeIds = []
		const tagIds = tagObjs.map((it) => Number(it.id))
		const allSubUserIds = this.getSiteDataTable("sub_users").map((it) => it.id)

		allSubUserIds.forEach((userId) => {
			for (let tagId of tagIds) {
				const tagUserObj = this.getSiteDataTable("object_to_tags").find(
					(it) =>
						it.item_type == "user" && it.tag_id == tagId && it.item_id == userId
				)
				if (!tagUserObj) {
					excludeIds.push(userId)
					return
				}
			}
		})
		return excludeIds
	}

	async sendFillDetailsApprove(id: number) {
		await this.apiService.post("approve_patient_fill_details", {
			patient_id: id,
		})
		this.modalService.openToast(this.lang.getVal("updated_from_patient_form"))
		await this.refreshSiteDataPatientsByIds([id])
		setTimeout(() => {
			window.location.reload()
		}, 2000)
		//
	}

	fillObjForTags(tagObjs: any[], obj: TagsObj, item_type: string = "user") {
		obj.hasAny = !!tagObjs?.length

		if (tagObjs?.length) {
			obj.ids = []
			const tagIds = tagObjs.map((it) => Number(it.id))
			//get user ids to tags
			const idsToTagArray = {}
			const o2t = this.getSiteDataTable("object_to_tags").filter(
				(row) => row.item_type == item_type
			)
			o2t.forEach((o2tRow) => {
				if (!idsToTagArray[o2tRow.item_id]) {
					idsToTagArray[o2tRow.item_id] = []
				}
				idsToTagArray[o2tRow.item_id].push(Number(o2tRow.tag_id))
			})
			//only get total cross of arrays
			Object.keys(idsToTagArray).forEach((id) => {
				const tagArray = idsToTagArray[id]
				if (tagIds.every((tag_id) => tagArray.includes(tag_id))) {
					obj.ids.push(Number(id))
				}
			})
		}
	}
	async getFutureFuses(patient_id: number) {
		const res = await this.getAllFus()
		const today = moment.utc()
		const rows = res.followupseries.filter((fus) => {
			if (fus?.patient_id != patient_id) {
				return false
			}
			if (fus?.status != "active") {
				return false
			}

			if (fus.series_repeat == "one_time") {
				if (moment.utc(fus.date).isBefore(today)) {
					return false
				}
			} else {
				if (
					fus.series_limit_date &&
					moment.utc(fus.series_limit_date).isBefore(today)
				) {
					return false
				}
			}
			return true
		})
		rows.forEach((fus) => {
			fus.dayName =
				(OptionsList.days_of_week_opts as any[]).find(
					(it) => it.value == fus.start_day
				)?.lang || ""
			fus.repeatName =
				(OptionsList.series_repeat_opts as any[]).find(
					(it) => it.value == fus.series_repeat
				)?.lang || ""
			fus.meetingDate =
				fus.series_repeat == "one_time"
					? this.getMomentDisplay(fus.date)
					: fus.repeatName
		})
		return rows
	}

	getPrintStyle() {
		const style = `
    body{
      font-family:'Heebo', sans-serif;
    }

		// .header{
		// 	width:100%;
		// }
		// @media print{
		// 	.header{
		// 		position:fixed;
		// 		top:0;
		// 		// margin-top:-10px;
		// 	}
		// }

		.print-show{
			display:table-cell;
		}
		.print_hidden{
			display:none;
		}
		.table-rows th,.table-rows td{
			width: 10%;
			border-bottom: 1px solid grey;
			text-align:right;
		}
		.table-rows tr{
			border-bottom: 1px solid grey;
		}
		.totals-row{
			font-weight: bold;
		}
    `
		return style
	}
	getPrintStyle2() {
		const style = `
    body{
      font-family:'Heebo', sans-serif;
    }
		.print-show{
			display:table-cell;
		}
		tr th,
		tr td {
			width: 80px;
		}
		tr th:first-child,
		tr td:first-child {
			width: 200px;
		}
		th,td{
			border-bottom: 1px solid grey;
			text-align:right;
		}
		tr{
			border-bottom: 1px solid grey;
		}
		.totals-row{
			font-weight: bold;
		}
    `
		return style
	}

	numToHebPillsHund(n: number, isMale: boolean, otherWord: string) {
		const hund = Math.floor(n / 100)
		let word = [nums.f[hund - 1], "מאות"].join(" ")
		if (hund < 3) {
			word = hund === 1 ? "מאה" : "מאתיים"
		}
		const underHundNum = n % 100
		if (!underHundNum) {
			return [word, otherWord].join(" ")
		}
		let underHundWord = nums[isMale ? "m" : "f"][underHundNum - 1]
		if (underHundNum == 2) {
			underHundWord += "ם"
		}
		if (underHundNum <= 2) {
			return [word, "ו" + underHundWord, otherWord].join(" ")
		}
		if (underHundNum <= 20 || underHundNum % 10 == 0) {
			return [
				word,
				"ו" + this.numToHebPillsUnder100(underHundNum, isMale, otherWord),
			].join(" ")
		}
		return [
			word,
			this.numToHebPillsUnder100(underHundNum, isMale, otherWord),
		].join(" ")
	}
	numToHebPills(n: number, isMale: boolean, otherWord: string) {
		if (n == 0.25) {
			return ["רבע", otherWord].join(" ")
		}
		if (n == 0.4) {
			return [40, "מאיות", otherWord].join(" ")
		}
		if (n == 0.5) {
			return ["חצי", otherWord].join(" ")
		}
		if (n == 0.75) {
			return [75, "מאיות", otherWord].join(" ")
		}
		if (n == 3.75) {
			return ["שלושה ושבעים וחמש מאיות", otherWord].join(" ")
		}
		return n >= 100
			? this.numToHebPillsHund(n, isMale, otherWord)
			: this.numToHebPillsUnder100(n, isMale, otherWord)
	}
	numToHebPillsUnder100(n: number, isMale: boolean, otherWord: string) {
		const pos = (n - 1) % 10
		let word = nums[isMale ? "m" : "f"][pos]
		if (n <= 10) {
			if (n == 1) {
				return [otherWord, word].join(" ")
			}
			return [word, otherWord].join(" ")
		}
		if (pos === 1) {
			word += "ם"
		}
		if (n < 20) {
			const tenWord = nums[isMale ? "f" : "m"][9]
			return [word, tenWord, otherWord].join(" ")
		}
		word = "ו" + word

		const tenWord = nums.mult[Math.floor(n / 10)]
		if (!(n % 10)) {
			return [tenWord, otherWord].join(" ")
		}
		return [tenWord, word, otherWord].join(" ")
	}
	async printWindow(htmlDoc: string, avoidClose: boolean = false) {
		const isAndroid = /android/i.test(navigator.userAgent)
		if (isAndroid) {
			htmlDoc = htmlDoc.replace("window.print()", "")
		}
		let newWin = window.open("", "Print-Window")
		newWin.document.open()
		newWin.document.write(htmlDoc)
		newWin.document.close()
		if (avoidClose || isAndroid) {
			return
		}
		await this.timeout(10)
		newWin.close()
	}
	async getApiLink() {
		if (this.apiLink) {
			return this.apiLink
		}
		const res = await this.apiService.post("get_api_data")
		this.apiLink = location.origin + res?.link
		return this.apiLink
	}
}
