<div
	class="gen-table-wrapper"
	style="margin-inline: 1.5%"
>
	@if (!addOnlyVis) {
		<div class="gen-tbl-header-top">
			<h4 class="gen-title">
				<span class="gen-title-title">{{ langProps.allName | langVal }}</span>
				<span class="num-badge">{{ objectCollectionShown.length }}</span>

				@if (
					apiCallPerms.saveNewRow &&
					!isEditDisabled &&
					!avoidNewRow &&
					(addRowIsByPlus || !isNewOpen)
				) {
					<button
						class="btn btn-icon"
						titleLang="add"
						style="margin-inline: 10px !important"
						(click)="prepNewRow()"
						icon="plus"
					></button>
				}
				@if (hasFieldHiding) {
					<div
						style="display: inline-block"
						(click)="$event.stopImmediatePropagation()"
					>
						<app-icon
							icon="filter"
							class="gen-filter"
							pointer
							(click)="isFieldHidingFloatOpen = !isFieldHidingFloatOpen"
						/>
						@if (isFieldHidingFloatOpen) {
							<div class="gen-filter vis-flip-div">
								@for (fieldObj of collectionFields; track fieldObj) {
									@if (fieldObj.isHideable) {
										<div>
											<input
												type="checkbox"
												[checked]="!fieldObj.curHidden"
												(change)="fieldObj.curHidden = !$event.target.checked"
											/>
											{{ fieldObj.langName | langVal }}
										</div>
									}
								}
							</div>
						}
					</div>
				}
				@for (btn of headerModalOpenBtns; track btn) {
					@if (!btn?.visibleCheckedOnly) {
						<button
							class="btn btn-icon"
							[titleLang]="btn.hoverLang"
							(click)="headerModalOpenBtnClick(btn)"
							[icon]="btn.icon"
						></button>
					}
				}
				@if (!hideExport) {
					<button
						class="btn btn-icon"
						titleLang="export"
						(click)="exportRows()"
						icon="export"
					></button>
				}
			</h4>
			@if (searchableFields.length) {
				<div
					class="gen-tbl-search"
					[spinner]="isSearching"
				>
					<app-gen-input
						[actualObj]="this"
						fieldName="currentSearch"
						(keyupEvent)="search()"
						title_ph_lang="search"
						[addonObject]="{ after: { icon: 'search' } }"
						[tabindex]="-1"
					/>
					@if (currentSearch?.length) {
						<app-icon
							icon="close"
							pointer
							class="clear-search"
							(click)="clearSearch()"
						/>
					}
				</div>
			}

			@if (hasAssocFilter) {
				<div class="gen-tbl-search">
					<app-autocomplete-with-tags
						(chosenEvent)="changeToFilterAssocUsers($event)"
						title_ph_lang="assoc"
						[fuzzySearchableClasses]="['sub_users']"
					/>
				</div>
			}
			@if (hasTagsFilter) {
				<div class="gen-tbl-search">
					<app-autocomplete-with-tags
						(chosenEvent)="changeToFilterTags($event)"
						title_ph_lang="tags"
					/>
				</div>
			}

			@if (tableName == "inquirers" && inquiry_statuses_names.length) {
				<div class="gen-tbl-search">
					<app-gen-select
						[actualObj]="this"
						fieldName="inquiry_status_id"
						title_ph_lang="patienttype"
						(changeEvent)="changeToFilterInquiryStatus()"
						[options]="inquiry_statuses_names"
					/>
				</div>
			}

			<div class="filter-row">
				@if (filterFields.length) {
					@for (filterObj of filterFields; track filterObj) {
						<div>
							<app-gen-button-group
								[actualObj]="currentFilterValues"
								[fieldName]="filterObj.fieldName"
								[options]="filterObj.opts"
								optionFieldId="value"
								(changeEvent)="runFilters()"
							/>
						</div>
					}
					@if (filterFields.length > 1) {
						<div>
							<div class="button-group">
								<button
									class="btn btn-default"
									(click)="resetFilters(true)"
									titleLang="reset_filters"
								>
									{{ "reset_filters" | langVal }}
								</button>
							</div>
						</div>
					}
				}
				@if (tableName | inArray: ["patients", "inquirers"]) {
					<div class="colstat colstat-extra-small">
						<app-gen-input
							[actualObj]="this"
							fieldName="ageSearch"
							title_ph_lang="age"
							(keyupEvent)="search()"
						/>
					</div>
				}
			</div>
			<ng-content select="[slot=upper-row]"></ng-content>
		</div>
		@if (tableName == "expenses") {
			<div class="row">
				<div class="col-12 col-md-4 col-lg-6 flex-inline-start">
					<app-gen-button-group
						optionsListName="base_periods_no_range"
						title_ph_lang="period"
						[actualObj]="this"
						fieldName="period"
						(changeEvent)="search()"
					/>
				</div>
			</div>
		}
	}

	<!-- end of mobile -->
	<!-- wrapper -->

	<ng-content></ng-content>

	@if (hasCheckedItems) {
		<div
			style="background-color: #edf9eb"
			[spinner]="isSendingMulti"
		>
			@for (btn of headerModalOpenBtns; track btn) {
				@if (btn?.visibleCheckedOnly) {
					@if (btn?.textOnly) {
						<button
							class="btn btn-primary"
							(click)="headerModalOpenBtnClick(btn)"
						>
							{{ btn.hoverLang | langVal }}
						</button>
					} @else {
						<button
							class="btn"
							[titleLang]="btn.hoverLang"
							(click)="headerModalOpenBtnClick(btn)"
							[icon]="btn.icon"
						></button>
					}
				}
			}
		</div>
	}

	@if (isTableScrollable) {
		<div
			[style.height]="scrollViewHeight + 'px'"
			style="overflow: auto; width: 100%"
			(scroll)="onSc($event)"
			#scollContainer
		>
			<div
				#itemsDiv
				style="transform: translateY(0)"
			>
				<ng-container
					*ngTemplateOutlet="tableElContainer; context: { isScroll: true }"
				/>
			</div>
			<div
				style="width: 1px"
				[style.height]="scrollTotalHeight + 'px'"
			></div>
		</div>
	} @else {
		<ng-container
			*ngTemplateOutlet="tableElContainer; context: { isScroll: false }"
		/>
	}

	<ng-template
		#tableElContainer
		let-isScroll="isScroll"
	>
		<table
			#tableEl
			class="table pad-block"
		>
			<thead>
				<tr>
					@if (expanded) {
						<th
							style="padding-inline: 0"
							titleLang="expand"
						>
							<button
								(click)="expandAll()"
								class="btn btn-icon"
								titleLang="expand"
								[icon]="isAllExpanded ? 'narrow' : 'expand'"
							></button>
						</th>
					}
					@if (isCheckItems) {
						<th
							titleLang="check_items"
							class="action-fields"
						>
							<app-yes-no
								(onClick)="
									chooseAllChecked = !chooseAllChecked;
									chooseAll(chooseAllChecked)
								"
								[isCheck]="chooseAllChecked"
							/>
						</th>
					}
					@for (fieldObj of upperCollectionFields; track fieldObj.fieldName) {
						@if (fieldObj.type === "string") {
							<th></th>
						} @else {
							@if (
								!fieldObj.curHidden &&
								fieldObj.type !== "hidden" &&
								((eventsService.isMobile && !fieldObj?.hideOnMobile) ||
									(!eventsService.isMobile && !fieldObj?.hideOnNonMobile))
							) {
								<th
									(click)="sort(fieldObj.fieldName)"
									sorter
									[fieldName]="fieldObj.fieldName"
									[displayName]="fieldObj.langName | langVal"
									[sortIsAsc]="sortIsAsc"
									[sortField]="sortField"
									[isSortable]="fieldObj.isSortable"
								></th>
							}
						}
					}
					@if (
						(apiCallPerms.deleteObject || updateRowOverride) && !isEditDisabled
					) {
						<th
							class="text-muted d-none d-md-table-cell"
							icon="wrench"
							titleLang="delete"
						></th>
					}
				</tr>
			</thead>

			@if (
				apiCallPerms.saveNewRow && isNewOpen && !isEditDisabled && !avoidNewRow
			) {
				<tbody>
					<tr class="new-row">
						@if (expanded) {
							<td style="width: 10px"></td>
						}
						@if (isCheckItems) {
							<td></td>
						}
						@for (fieldObj of upperCollectionFields; track fieldObj.fieldName) {
							@if (
								!fieldObj.curHidden &&
								fieldObj.type !== "hidden" &&
								((eventsService.isMobile && !fieldObj?.hideOnMobile) ||
									(!eventsService.isMobile && !fieldObj?.hideOnNonMobile))
							) {
								<td
									[style]="{
										width: fieldObj?.attrs?.width || 'auto',
										minWidth: fieldObj?.attrs?.minWidth || 'inherit',
									}"
								>
									@if (!fieldObj.attrs?.nonVisNewRow) {
										<ng-container
											*ngTemplateOutlet="
												rowFields;
												context: {
													curRow: newRow,
													fieldObj: fieldObj,
													isNewRow: true,
												}
											"
										/>
									}
								</td>
							}
						}

						<td>
							@if (!expanded) {
								<div class="button-group add-btn flex-v-center">
									<button
										class="btn btn-icon"
										(click)="isNewOpen = false"
										icon="cancel"
									></button>
									<app-btn-ext
										#addRowBtn
										text="add"
										[disabled]="!isFormSubmittable"
										[tabindex]="tabindexDomain + 49"
										(clickEv)="addNewRow()"
										[isSpin]="isSaving"
									/>
								</div>
							}
						</td>
					</tr>
					@if (expanded) {
						<tr class="new-row">
							<td
								colspan="99999"
								class="expanded-td"
							>
								<div class="expanded-div">
									@for (
										fieldObj of lowerCollectionFields;
										track fieldObj.fieldName
									) {
										@if (
											!fieldObj.attrs?.nonVisNewRow &&
											!fieldObj.curHidden &&
											fieldObj.type !== "hidden" &&
											((eventsService.isMobile && !fieldObj?.hideOnMobile) ||
												(!eventsService.isMobile && !fieldObj?.hideOnNonMobile))
										) {
											<div
												[style]="{
													width: fieldObj?.attrs?.width || 'auto',
													minWidth: fieldObj?.attrs?.minWidth || 'inherit',
												}"
											>
												<label
													[class.transparent]="fieldObj.type === 'string'"
													>{{ fieldObj.langName | langVal }}</label
												>
												<ng-container
													*ngTemplateOutlet="
														rowFields;
														context: {
															curRow: newRow,
															fieldObj: fieldObj,
															isNewRow: true,
														}
													"
												>
												</ng-container>
											</div>
										}
									}
									<div class="button-group add-btn">
										<button
											class="btn btn-icon"
											(click)="isNewOpen = false"
											icon="cancel"
										></button>
										<app-btn-ext
											#addRowBtn
											text="add"
											[disabled]="!isFormSubmittable"
											[tabindex]="tabindexDomain + 49"
											(clickEv)="addNewRow()"
											[isSpin]="isSaving"
										/>
									</div>
								</div>
							</td>
						</tr>
					}
				</tbody>
				<tbody>
					<tr class="new-row-extra">
						<td colspan="99999"></td>
					</tr>
				</tbody>
			}

			@if (!addOnlyVis) {
				@if (isLoading) {
					<tbody>
						<tr>
							<td
								colspan="400"
								spinner
							>
								{{ "loading" | langVal }}
							</td>
						</tr>
					</tbody>
				} @else {
					<!-- start row -->
					@for (
						curRow of isClientPagi
							? (objectCollectionShown
								| slice
									: (clientPagiObj.curPage - 1) * clientPagiSize
									: clientPagiObj.curPage * clientPagiSize)
							: objectCollectionShown;
						track curRow
					) {
						<tbody>
							<tr
								(click)="
									curRow.isExpanded = true; changeItemsShownNonScroll(scrollTop)
								"
								[title]="curRow.html_content_normalized || ''"
								[class.highlight-row]="curRow?.highlight_row"
								[class.highlight-row2]="curRow?.highlight_row2"
							>
								@if (expanded) {
									<td class="action-fields">
										<button
											(click)="openExpandedRow($event, curRow)"
											class="btn btn-icon"
											[icon]="curRow.isExpanded ? 'narrow' : 'expand'"
											titleLang="expand"
										></button>
									</td>
								}
								@if (isCheckItems) {
									<td class="action-fields">
										<app-yes-no
											(onClick)="
												curRow.checked = !curRow.checked;
												emitCheckedItemsChanged()
											"
											[isCheck]="curRow.checked"
										/>
									</td>
								}

								@for (
									fieldObj of upperCollectionFields;
									track fieldObj.fieldName
								) {
									@if (
										!fieldObj.curHidden &&
										fieldObj.type !== "hidden" &&
										((eventsService.isMobile && !fieldObj?.hideOnMobile) ||
											(!eventsService.isMobile && !fieldObj?.hideOnNonMobile))
									) {
										<td
											[style]="{
												width: fieldObj?.attrs?.width || 'auto',
												minWidth: fieldObj?.attrs?.minWidth || 'inherit',
											}"
										>
											<ng-container
												*ngTemplateOutlet="
													rowFields;
													context: {
														curRow: curRow,
														fieldObj: fieldObj,
														isStatic:
															!apiCallPerms.saveField ||
															isEditDisabled ||
															curRow?.is_locked,
													}
												"
											/>
										</td>
									}
								}

								@if (
									(apiCallPerms.deleteObject || updateRowOverride) &&
									!isEditDisabled
								) {
									<td class="d-none d-md-table-cell">
										<ng-container
											*ngTemplateOutlet="
												deleteBtnEtc;
												context: { curRow: curRow }
											"
										/>
									</td>
								}
							</tr>
							@if (expanded && curRow.isExpanded) {
								<tr [class.grey]="$odd">
									<td
										colspan="99999"
										class="expanded-td"
									>
										<div class="expanded-div">
											@for (
												fieldObj of lowerCollectionFields;
												track fieldObj.fieldName
											) {
												@if (
													!fieldObj.curHidden &&
													fieldObj.type !== "hidden" &&
													((eventsService.isMobile &&
														!fieldObj?.hideOnMobile) ||
														(!eventsService.isMobile &&
															!fieldObj?.hideOnNonMobile))
												) {
													<div
														[style]="{
															width: fieldObj?.attrs?.width || 'auto',
															minWidth: fieldObj?.attrs?.minWidth || 'inherit',
														}"
													>
														<label
															[class.transparent]="fieldObj.type === 'string'"
															>{{ fieldObj.langName | langVal }}</label
														>
														<ng-container
															*ngTemplateOutlet="
																rowFields;
																context: {
																	curRow: curRow,
																	fieldObj: fieldObj,
																	isStatic:
																		!apiCallPerms.saveField ||
																		isEditDisabled ||
																		curRow?.is_locked,
																}
															"
														>
														</ng-container>
													</div>
												}
											}
											<div class="d-md-none actions">
												<ng-container
													*ngTemplateOutlet="
														deleteBtnEtc;
														context: { curRow: curRow }
													"
												/>
											</div>
										</div>
									</td>
								</tr>
							}
						</tbody>
					} @empty {
						<tbody>
							<tr>
								<td colspan="400">{{ "no_rows_yet" | langVal }}</td>
							</tr>
						</tbody>
					}
				}
				<!-- end row -->
			}
		</table>
	</ng-template>

	@if (addOnlyVis && !restrictedAddMode) {
		<div
			class="text-center"
			style="margin-top: 20px"
		>
			<button
				(click)="addOnlyVis = false"
				class="btn btn-primary"
			>
				{{ "view_all" | langVal }}
			</button>
		</div>
	}

	@if (isClientPagi) {
		<div>
			@if (clientPagiObj.curPage != 1) {
				<button
					class="cal-btn"
					(click)="clientPaginate(-1)"
				>
					&lt;
				</button>
			}
			<span>{{ clientPagiObj.curPage }} / {{ clientPagiObj.lastPage }}</span>
			@if (clientPagiObj.curPage < clientPagiObj.lastPage) {
				<button
					class="cal-btn"
					(click)="clientPaginate(1)"
				>
					&gt;
				</button>
			}
		</div>
	}

	@if (!addOnlyVis && paginationObj) {
		<div>
			@if (paginationObj.curPage != 1) {
				<button
					class="cal-btn"
					(click)="paginate(-1)"
				>
					&lt;
				</button>
			}
			<span>{{ paginationObj.curPage }} / {{ paginationObj.lastPage }}</span>
			@if (paginationObj.curPage < paginationObj.lastPage) {
				<button
					class="cal-btn"
					(click)="paginate(1)"
				>
					&gt;
				</button>
			}
		</div>
	}
</div>

<ng-template
	#rowFields
	let-curRow="curRow"
	let-fieldObj="fieldObj"
	let-isNewRow="isNewRow"
	let-isStatic="isStatic"
>
	<!-- @if(true || (!isNewRow && fieldObj?.attrs?.staticOnEdit)){ -->
	@if (!isNewRow && fieldObj?.attrs?.staticOnEdit) {
		<!-- only here if field is static on edit -->
		@switch (fieldObj.type) {
			@case ("wysiwyg") {
				<div
					class="static"
					[innerHTML]="
						fieldObj.attrs?.outputLang
							? curRow[fieldObj.fieldName | langVal]
							: (curRow[fieldObj.fieldName] | safeHtml)
					"
				></div>
			}
			@case ("date") {
				<div
					class="static"
					[innerHTML]="curRow[fieldObj.fieldName] | dateFormat | safeHtml"
				></div>
			}
			@case ("time") {
				<div
					class="static"
					[innerHTML]="curRow[fieldObj.fieldName] | timeFormat | safeHtml"
				></div>
			}
			@default {
				<div class="static">
					{{
						curRow[fieldObj.fieldName + "_staticDisplayValue"] ??
							curRow[fieldObj.fieldName]
					}}
				</div>
			}
		}
	} @else {
		<!-- either I'm new row OR I'm existing row and not static -->

		@if (fieldObj.type | inArray: ["input", "textarea", "color"]) {
			@if (fieldObj.type == "color") {
				<span
					style="z-index: 30; position: absolute; margin-top: -2px"
					(click)="colorClear(curRow, fieldObj, isNewRow)"
					icon="cancel"
					titleLang="clear"
				></span>
			}

			<app-gen-input
				[errors]="curRow.errors"
				[actualObj]="curRow"
				[fieldName]="fieldObj.fieldName"
				(keyupEvent)="validateField(curRow, fieldObj.fieldName, isNewRow)"
				[type]="fieldObj.type"
				[isStatic]="isStatic"
				[height]="fieldObj.attrs?.height || ''"
				#focusableField
				[isNumber]="fieldObj?.isNumberField"
				[numberKeyboard]="fieldObj.type === 'input' && fieldObj?.isNumberField"
				(changeEvent)="
					isNewRow
						? validateEntireForm()
						: validateAndSaveField(curRow, fieldObj)
				"
				[genTableVars]="{
					curRow: curRow,
					fieldObj: fieldObj,
					isNewRow: isNewRow,
					tabindexDomain: tabindexDomain,
				}"
			/>
		}
		@if (fieldObj.type | inArray: ["file", "image"]) {
			<app-gen-file-input
				[fileType]="fieldObj.type"
				[fieldName]="fieldObj.fieldName"
				[actualObj]="curRow"
				[errors]="curRow.errors"
				[urlTemplate]="fieldObj.attrs.urlTemplate"
				[maxDims]="fieldObj.attrs?.maxDims"
				[isStatic]="isStatic"
				(changeEvent)="
					updFilesObj(curRow, fieldObj.fieldName, $event);
					isNewRow
						? validateEntireForm()
						: validateAndSaveField(curRow, fieldObj)
				"
				[genTableVars]="{
					curRow: curRow,
					fieldObj: fieldObj,
					isNewRow: isNewRow,
					tabindexDomain: tabindexDomain,
				}"
			/>
		}
		@if (fieldObj.type | inArray: ["fuzzy", "fuzzy-api"]) {
			<div class="fuzzy-wrap">
				<app-gen-autocomplete
					#focusableField
					[error]="curRow.errors[fieldObj.fieldName]"
					[fieldName]="fieldObj.fieldName"
					[searchStr]="curRow[fieldObj.attrs.displayStringFieldName] || ''"
					[fuzzySearchableClasses]="
						fieldObj.type == 'fuzzy' ? fieldObj.fuzzyArr : []
					"
					(chosenEvent)="autocompleteChoice($event, curRow, fieldObj, isNewRow)"
					(keyUp)="autocompleteKeyUp($event, curRow, fieldObj, isNewRow)"
					[actualObj]="curRow"
					[fuzzySearchObj]="fieldObj.attrs?.fuzzySearchObj"
					[optionDisplay]="fieldObj.attrs?.optionDisplay"
					[objectFieldToSave]="fieldObj.attrs.objectFieldToSave || 'id'"
					[width]="'100%'"
					[outerFilterValue]="
						curRow[fieldObj.attrs?.fuzzySearchObj?.outerFilterName]
					"
					[searchStrFieldName]="fieldObj.attrs.displayStringFieldName"
					[isStatic]="isStatic"
					[hasPlus]="
						!fieldObj.attrs.avoidPlus &&
						(!fieldObj.attrs?.showPlusPerm ||
							permsService.perms[fieldObj.attrs?.showPlusPerm])
					"
					[plusLang]="fieldObj?.langName"
					(plusClick)="openAddFuzzyModal(curRow, fieldObj, isNewRow)"
					[genTableVars]="{
						curRow: curRow,
						fieldObj: fieldObj,
						isNewRow: isNewRow,
						tabindexDomain: tabindexDomain,
					}"
				/>
				<!-- show the plus if both:
        1. there's no avoid
        2. either THERE IS NO NEED FOR PERM or THERE IS A NEED FOR PERM AND I HAVE IT -->
			</div>
		}

		@switch (fieldObj.type) {
			@case ("staticIcon") {
				<span
					[titleLang]="curRow[fieldObj.langName]"
					class="flex-center"
					[icon]="curRow[fieldObj.fieldName]"
				></span>
			}
			@case ("static") {
				@if (!fieldObj.attrs?.ngIfFunc || fieldObj.attrs?.ngIfFunc(curRow)) {
					@if (fieldObj.attrs?.outputLang) {
						<div
							class="static"
							[innerHTML]="curRow[fieldObj.fieldName] | langVal | safeHtml"
						></div>
					} @else {
						<div
							class="static"
							[innerHTML]="
								curRow[fieldObj.fieldName + '_staticDisplayValue'] ??
									(curRow[fieldObj.fieldName] || '') | safeHtml
							"
						></div>
					}
				}
			}
			@case ("button-group") {
				@if (!fieldObj.attrs?.ngIfFunc || fieldObj.attrs?.ngIfFunc(curRow)) {
					<app-gen-button-group
						[actualObj]="curRow"
						[fieldName]="fieldObj.fieldName"
						[options]="fieldObj.optionsCollection"
						[errors]="curRow.errors"
						[disabled]="isStatic"
						(changeEvent)="
							isNewRow
								? validateEntireForm(curRow, fieldObj)
								: validateAndSaveField(curRow, fieldObj)
						"
						[genTableVars]="{
							curRow: curRow,
							fieldObj: fieldObj,
							isNewRow: isNewRow,
							tabindexDomain: tabindexDomain,
						}"
					/>
				}
			}
			@case ("yes-no") {
				<div>
					<app-yes-no
						(onClick)="yesNoClick(curRow, fieldObj, isNewRow)"
						[isCheck]="curRow[fieldObj.fieldName] == 'yes'"
						[disabled]="isStatic"
					/>
					@if (curRow.errors[fieldObj.fieldName]) {
						<p class="error">{{ curRow.errors[fieldObj.fieldName] }}</p>
					}
				</div>
			}
			@case ("select") {
				<app-gen-select
					[actualObj]="curRow"
					[options]="fieldObj.optionsCollection"
					[fieldName]="fieldObj.fieldName"
					#focusableField
					optionFieldId="value"
					[errors]="curRow.errors"
					[disabled]="
						!isNewRow &&
						(!apiCallPerms.saveField || isEditDisabled || curRow?.is_locked)
					"
					(changeEvent)="
						isNewRow
							? validateEntireForm()
							: validateAndSaveField(curRow, fieldObj)
					"
					[genTableVars]="{
						curRow: curRow,
						fieldObj: fieldObj,
						isNewRow: isNewRow,
						tabindexDomain: tabindexDomain,
					}"
				/>
			}
			@case ("wysiwyg") {
				<app-wysiwyg
					[actualObj]="curRow"
					[fieldName]="fieldObj.fieldName"
					[isStatic]="isStatic"
					#focusableField
					(changeEvent)="
						isNewRow
							? validateEntireForm()
							: validateAndSaveField(curRow, fieldObj)
					"
					[isDocumentTemplate]="tableName == 'documenttemplates'"
					[genTableVars]="{
						curRow: curRow,
						fieldObj: fieldObj,
						isNewRow: isNewRow,
						tabindexDomain: tabindexDomain,
					}"
				/>
			}
			@case ("tags-ac") {
				<div>
					@if (isNewRow) {
						<app-autocomplete-with-tags
							#newRowTags
							[hasPlus]="!fieldObj.attrs?.avoidPlus"
							(chosenEvent)="newRowTagsChoice($event, fieldObj)"
						/>
					} @else {
						<app-autocomplete-with-tags
							saveMode
							[hasPlus]="
								!fieldObj.attrs?.avoidPlus &&
								!(!apiCallPerms.saveField || isEditDisabled)
							"
							[item_id]="curRow.id"
							[item_type]="fieldObj.attrs?.tagItemType"
							[isStatic]="isStatic"
						/>
					}
				</div>
			}
			@case ("autocomplete-multi") {
				<div>
					@if (isNewRow) {
						<app-autocomplete-multi
							#newRowAssoc
							(chosenEvent)="newRowAddAssoc($event, fieldObj)"
							[excludeIds]="excludeIds"
							[isStatic]="isStatic"
							[siteDataFilters]="{ active: 'yes' }"
							siteDataTable="sub_users"
						/>
					} @else {
						<app-autocomplete-multi
							siteDataTable="sub_users"
							[ids]="curRow[fieldObj.fieldName]"
							(addItem)="assocAddItem($event, curRow, fieldObj)"
							[siteDataFilters]="{ active: 'yes' }"
							[isStatic]="isStatic"
							(deleteItem)="assocDeleteItem($event, curRow, fieldObj)"
							[excludeIds]="excludeIds"
						/>
					}
				</div>
			}
			@case ("date") {
				<app-date-picker
					[actualObj]="curRow"
					[fieldName]="fieldObj.fieldName"
					[errors]="curRow.errors"
					[isStatic]="isStatic"
					(changeEvent)="
						updateDateField(curRow, fieldObj);
						isNewRow
							? validateEntireForm()
							: validateAndSaveField(curRow, fieldObj);
						tryToFocusOnNextField(curRow, fieldObj, isNewRow)
					"
					[genTableVars]="{
						curRow: curRow,
						fieldObj: fieldObj,
						isNewRow: isNewRow,
						tabindexDomain: tabindexDomain,
					}"
					[disabledAfterToday]="fieldObj?.attrs?.disabledAfterToday"
				/>
			}
			@case ("time") {
				<app-time-picker
					[actualObj]="curRow"
					[fieldName]="fieldObj.fieldName"
					[errors]="curRow.errors"
					[isStatic]="isStatic"
					(changeEvent)="
						isNewRow
							? validateEntireForm()
							: validateAndSaveField(curRow, fieldObj)
					"
					[genTableVars]="{
						curRow: curRow,
						fieldObj: fieldObj,
						isNewRow: isNewRow,
						tabindexDomain: tabindexDomain,
					}"
				/>
			}
			@case ("button") {
				@if (isNewRow) {
					@if (fieldObj.fieldName == "assoc") {
						<app-gen-autocomplete
							#focusableField
							[error]="curRow.errors[fieldObj.fieldName]"
							[fieldName]="fieldObj.fieldName"
							[fuzzySearchableClasses]="['sub_users']"
							[searchStr]="curRow?.assoc_name || ''"
							[searchStrFieldName]="'assoc_name'"
							(chosenEvent)="
								autocompleteChoice($event, curRow, fieldObj, isNewRow)
							"
							(keyUp)="autocompleteKeyUp($event, curRow, fieldObj, isNewRow)"
							[actualObj]="curRow"
							[width]="'100%'"
							[genTableVars]="{
								curRow: curRow,
								fieldObj: fieldObj,
								isNewRow: isNewRow,
								tabindexDomain: tabindexDomain,
							}"
						/>
					}

					@if (fieldObj?.attrs?.isInputInNewRow) {
						<app-gen-input
							[fieldName]="fieldObj.fieldName"
							[actualObj]="curRow"
							[errors]="curRow.errors"
							#focusableField
							(keyupEvent)="validateField(curRow, fieldObj.fieldName, isNewRow)"
							[height]="fieldObj.attrs?.height || ''"
							(changeEvent)="validateEntireForm()"
							[genTableVars]="{
								curRow: curRow,
								fieldObj: fieldObj,
								isNewRow: isNewRow,
								tabindexDomain: tabindexDomain,
							}"
						/>
					}
				} @else {
					@if (
						!fieldObj.attrs?.isShownField || curRow[fieldObj.attrs.isShownField]
					) {
						<button
							class="btn btn-icon"
							(click)="buttonFieldClick(curRow, fieldObj)"
						>
							<app-icon
								[icon]="fieldObj.attrs.icon"
								[isImg]="fieldObj.attrs.isImg"
							/>
						</button>
						<!-- visible if not new row AND
              EITHER isShownField doesn't exist OR isShownField has a truthy value in this row (ex. isCreditable)  -->
					}
				}
			}
			@case ("string") {
				<div class="static">{{ fieldObj.fieldName }}</div>
			}
			@case ("otherSiteDataTableValue") {
				<div class="static">
					{{ otherSiteDataTableValue(curRow, fieldObj) }}
				</div>
			}
			@case ("otherSiteDataTableValueMinusField") {
				<div class="static">
					{{ otherSiteDataTableValueMinusField(curRow, fieldObj) }}
				</div>
			}
		}

		@if (!isNewRow) {
			@switch (fieldObj.type) {
				@case ("inquiry-status") {
					<div class="static">
						{{ inquiry_statuses_names_obj[curRow.inquiry_status_id] || "" }}
					</div>
				}
				@case ("action-button") {
					@if (fieldObj.attrs.ngIfFunc(curRow)) {
						<app-spin-or-content
							[isSpin]="curRow[fieldObj.fieldName + '_isSpin']"
						>
							@if (fieldObj?.attrs?.icon) {
								<button
									class="btn"
									[icon]="fieldObj?.attrs?.icon"
									(click)="this[fieldObj.attrs.clickFunc](curRow, fieldObj)"
								></button>
							} @else {
								<button
									class="btn btn-primary"
									(click)="this[fieldObj.attrs.clickFunc](curRow, fieldObj)"
								>
									{{ fieldObj.langName | langVal }}
								</button>
							}
						</app-spin-or-content>
					}
				}
				@case ("show-item") {
					<button
						class="btn btn-icon"
						[routerLink]="fieldObj.attrs?.showItemBtnUrl + curRow.id"
						icon="show"
					></button>
				}
				@case ("external-link") {
					<button
						class="btn btn-icon"
						(click)="goToExternalLink(curRow.id)"
						icon="copy"
					></button>
				}
				@case ("whatsapp") {
					@if (curRow?.contactway_identifier == "phone") {
						<button class="btn btn-icon">
							<a
								[href]="
									'https://' +
									(eventsService.isMobile ? 'api' : 'web') +
									'.whatsapp.com/send/?phone=972' +
									curRow?.contactway
								"
								target="_blank"
								icon="whatsapp"
							></a>
						</button>
						<!--https://api.whatsapp.com/send/?phone=0544656100-->
					}
					@if (curRow?.phone_mobile) {
						<button class="btn btn-icon">
							<a
								[href]="
									'https://' +
									(eventsService.isMobile ? 'api' : 'web') +
									'.whatsapp.com/send/?phone=972' +
									curRow?.phone_mobile
								"
								target="_blank"
								icon="whatsapp"
							></a>
						</button>
					}
				}
				@case ("phone") {
					@if (curRow?.contactway_identifier == "phone") {
						<button class="btn btn-icon">
							<a
								[href]="'tel:' + curRow?.contactway"
								target="_blank"
								icon="phone"
							></a>
						</button>
					}
				}
				@case ("pdf-view") {
					@if (!fieldObj.attrs?.ngIfFunc || fieldObj.attrs?.ngIfFunc(curRow)) {
						<app-spin-or-content [isSpin]="curRow?.pdfViewSpin">
							<button
								class="btn btn-icon"
								(click)="downloadAndViewResource(curRow)"
								titleLang="pdf-view"
								icon="preview"
							></button>
						</app-spin-or-content>
					}
				}
				@case ("download") {
					@if (
						curRow.type || fieldObj?.attrs?.downloadType
							| inArray: downloadAndEmailTypes
					) {
						<app-spin-or-content [isSpin]="curRow?.downloadSpin">
							<button
								class="btn btn-icon"
								(click)="downloadFinResource(curRow)"
								titleLang="download"
								preIcon="download"
							>
								<span>{{ curRow.type_id || curRow.id }}</span>
							</button>
						</app-spin-or-content>
					}
				}
				@case ("duplicate") {
					<app-spin-or-content [isSpin]="curRow?.dupSpin">
						<button
							class="btn btn-icon"
							(click)="duplicate(curRow)"
							titleLang="duplicate"
							icon="copy"
						></button>
					</app-spin-or-content>
				}
				@case ("renew") {
					<app-spin-or-content [isSpin]="curRow?.renewSpin">
						<button
							class="btn btn-icon"
							(click)="renew(curRow)"
							titleLang="renew"
							icon="arow-left"
						></button>
					</app-spin-or-content>
				}
				@case ("document-action") {
					@if (curRow?.doc_type == "html") {
						<!--            signed:{{curRow?.signed}}-->
						<!--            for_signing:{{curRow?.for_signing}}-->
						<button
							class="btn btn-icon actions"
							(click)="documentAction(curRow)"
						>
							@if (curRow?.signed == "yes") {
								<app-icon icon="copy" />
							} @else {
								@if (curRow?.for_signing == "yes") {
									<app-icon icon="sign_document" />
								} @else {
									<app-icon icon="edit" />
								}
							}
						</button>
					}
				}
				@case ("mail-to") {
					@if (
						curRow.type || fieldObj?.attrs?.downloadType
							| inArray: downloadAndEmailTypes
					) {
						<button
							class="btn btn-icon"
							(click)="downloadAndEmail(curRow)"
							icon="email"
						></button>
					}
				}
			}
		}

		@if (fieldObj.attrs?.headturesInsert) {
			<button
				class="btn btn-primary btn-bottom"
				(click)="
					headturesInsert(curRow, fieldObj);
					isNewRow
						? validateEntireForm()
						: validateAndSaveField(curRow, fieldObj)
				"
			>
				{{ "generate_header" | langVal }}
			</button>
		}
	}
</ng-template>

<ng-template
	#deleteBtnEtc
	let-curRow="curRow"
>
	<div
		class="flex-v-center"
		[style.width]="updateRowOverride && apiCallPerms.deleteObject ? '' : '10px'"
	>
		@if (updateRowOverride) {
			<app-btn-ext
				text="update"
				[disabled]="!curRow.isRowSubmittable"
				(clickEv)="updateRow(curRow)"
				[isSpin]="curRow.isUpdatingSpinner"
			/>
		}

		<app-spin-or-content [isSpin]="curRow.isDeleting">
			@if (
				apiCallPerms.deleteObject &&
				!curRow.is_documented &&
				curRow?.for_signing != "yes" &&
				!curRow?.is_locked
			) {
				<button
					class="btn btn-icon"
					(click)="delete(curRow); $event.stopPropagation()"
					[disabled]="curRow.isDeleting"
					icon="delete"
				></button>
			}
		</app-spin-or-content>
	</div>
</ng-template>
